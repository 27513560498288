import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { User } from '../models/user';

@Injectable()
export class AuthGuard  {

  constructor(public user: User, public router: Router) {}

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): boolean {
    const expectedRole = route.data.expectedRole;
    if (this.user.isAuthenticated() && this.user.checkRole(expectedRole)) {
      localStorage.removeItem('redirectTo');
      return true;
    } else {
      localStorage.setItem('redirectTo', route['_routerState'].url);
      this.user.deleteToken();
      this.router.navigate(['users', 'signin'], { queryParams: { signedout: true } });
      return false;
    }
  }

}
