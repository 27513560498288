import { HttpClient } from "@angular/common/http";
import { customHttp } from "../../../utils/custom-http";
import { User } from "../../../models/user";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Client } from "../../../models/client";
import { AppConfig } from "../../../app.config";

@Injectable()

export class ClientFormService {
    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}`

    constructor(private http: HttpClient,
                private user: User) {
    }

    sendClient(data: any): Observable<any>{
        let url = `${this.endpoint}/${this.apiVersion}/clients`
        let token = this.user.getToken()

        return this.http.post(url, data)
    }
}