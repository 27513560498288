import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../app.config";
import { Client } from "../../../interfaces/cliente-interface";

@Injectable()
export class ClientEditService {

    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`

    constructor(private http: HttpClient){

    }

    sendForm(id: string, data: any){
        return this.http.put<Client>(`${this.endpoint}/clients/${id}`, data)
    }    
}