import { AbstractControl, UntypedFormGroup, FormControl, Validator, Validators, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { ValCnpj } from '../utils/cnpj-validator';

const URLREGEX = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

const PHONEREGEX = /^\([1-9]{2}\) [2-9][0-9]{3,4}\-[0-9]{4}$/;

const CNPJREGEX = /^([0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/;
const LETTERANDNUMBER = /^(?=.*?[A-Za-z_@./#&+-])(?=.*?[0-9]).{1,}$/;

export const validateUrl = (control: AbstractControl) => {
  return URLREGEX.test(control.value) ? null : {
    urlPattern: true
  };
};

export const validatePhone = (control: AbstractControl) => {
  return PHONEREGEX.test(control.value) ? null : {
    phonePattern: true
  };
};

export const validatesEqualFields = (group: UntypedFormGroup) => {
  const values = Object.keys(group.controls).map( key => group.controls[key].value );
  const valid = values.every(elem => elem === values[0]);
  return valid ? null : {
    areEquals: true
  };
};

export const validateCNPJ = (control: AbstractControl) => {
  return CNPJREGEX.test(control.value) ? null : {
    cnpjPattern: true
  };
};

export const validateCNPJval = (control: AbstractControl)=> {
   return ValCnpj(control.value) ? null : {
     cnpjPattern: true
   }
}

export const validateLetterAndNumber = (control: AbstractControl) => {
  return LETTERANDNUMBER.test(control.value) ? null : {
    letterNumberPattern: true
  };
};

export const validateGreaterThanField = (controlMax: string, controlMin: string): ValidatorFn => {
  return (group: UntypedFormGroup): {[key: string]: any} => {

    const maxControl = group.controls[controlMax]; 
    const minControl = group.controls[controlMin];

    if ( maxControl.disabled || minControl.disabled ) {
      return null;
    }
    if (!minControl.value || !maxControl.value) {
      return null;
    }

    if (minControl.value >= maxControl.value) {
      return { 'greaterThan': { min: minControl.value, max: maxControl.value } };
    }
    return null;
  };
};

export const validateDate = (control: AbstractControl) => {
  const dateFormat = 'DD/MM/YYYY';
    return moment(control.value, dateFormat, true).isValid() ? null : {
    date: true
  };
};

