import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { AppConfig } from "../app.config";

@Injectable({
    providedIn: 'root'
})
export class ReasonsService {

    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`;

    constructor(private http: HttpClient){

    }

    reasonsList(){

        return this.http.get<any>(`${this.endpoint}/reasons?active=true`)

    }

}
