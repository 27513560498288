import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../../app.config";
import { Injectable } from "@angular/core";



@Injectable()

export class ListReportsService {


    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`

    constructor(
      private http: HttpClient
      ){

    }

    getReports(){
      return this.http.get<any>(`${this.endpoint}/report`)
    }

    createReport(data){
      return this.http.post<any>(`${this.endpoint}/report`, data)
    }

    updateReport(id, data){
      return this.http.put<any>(`${this.endpoint}/report/${id}`, data)
    }

    updateStatus(id, status){
      return this.http.put<any>(`${this.endpoint}/report/status/${id}`, status)
    }

}
