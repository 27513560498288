import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../../../app.config";

@Injectable()

export class ClientActivationService {

apiVersion = `v${AppConfig.data['apiVersion']}`;
endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`

constructor(private http: HttpClient){

}

activeClient(id: string){
    return this.http.get<any>(`${this.endpoint}/users/activation/${id}`)
  }
}