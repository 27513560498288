import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";
import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha";

import { LoginComponent } from "./login.component";
import { LoginService } from "./login.service";
import { EmailLoginComponent } from "./email-login/email-login.component";
import { EmailResetCompoent } from "./email-reset/email-reset.component";
import { FieldsetHarukiModule, MMIconsModule } from "mm-ui";

const loginroute: Routes = [
    {path:'login', component: LoginComponent},
    {path:'email-reset', component: EmailResetCompoent},
    {path:'email-login/:token', component: EmailLoginComponent}
]

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FieldsetHarukiModule,
        RouterModule,
        RouterModule.forRoot(loginroute, {}),
        RecaptchaModule,
        RecaptchaFormsModule,
        MMIconsModule
    ],
    declarations: [
        LoginComponent,
        EmailResetCompoent,
        EmailLoginComponent
    ],
    providers:[
        LoginService,
    ]
})

export class LoginRoutingModule{

}
