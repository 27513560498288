import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { User } from "../models/user";
import {  AlertController } from "mm-ui";


@Injectable()

export class LoginGuard {
 
    constructor(
        private alertController: AlertController,
        public router:Router, 
        public user: User
    ){}

    canActivate(
        route: ActivatedRouteSnapshot
    ): boolean{
        if (this.user.isAuthenticated()) {
            return true
        } else {
            if(this.user.getToken()){
                this.alertController.configure({
                    error: 'login'
                })
                this.alertController.open();
                return false;
            } else {
                this.router.navigate(['login'])
                return false
            }
        }
    }
}