import { Pipe, PipeTransform } from '@angular/core';

import { LicenceTypes } from '../models/licenceTypes';

@Pipe({
  name: 'licence'
})
export class LicencePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return LicenceTypes[value] === undefined ? null : LicenceTypes[value];
  }

}
