<div class="container">
    <div class="row justify-content-center">
        <div class="col-9">
            <section class="mm-box-container">
                <header class="mm-box-header">Definição de Motivos</header>
                <div class="mm-box-content">
                    <div class="introduction-content">
                        <p>
                            Você alterou alguns campos e, por isso, é necessário justificar essa alteração com um motivo específico que deverá ser listado. Veja a seguir qual a alteração efetuada e liste um motivo para concluir a edição do cadastro deste cliente.
                        </p>
                    </div>
                    <div class="min-licenses" *ngIf="diffs.indexOf('qtMin')!==-1" >
                        <div class="text-style">
                            <p><strong>Quantidade Mínima de licenças contratadas</strong></p>
                        </div>
                        <div class="input-options">
                            <mmp5-fieldset-haruki
                                [formControl]="form.get([1]).get('reasonId')"
                                [key]="'options_qtMin'"
                                [label]="'Selecione o motivo'"
                                [selectOptions]="options"
                            ></mmp5-fieldset-haruki>
                        </div>
                    </div>
                    <div class="max-licenses" *ngIf="diffs.indexOf('qtMax')!==-1">
                        <div class="text-style">
                            <p><strong>Quantidade Máxima de licenças contratadas</strong> </p>
                        </div>
                        <div class="input-options">
                            <mmp5-fieldset-haruki
                            [formControl]="form.get([0]).get('reasonId')"
                            [key]="'options_qtMax'"
                            [label]="'Selecione o motivo'"
                            [selectOptions]="options"
                            ></mmp5-fieldset-haruki>
                        </div>
                    </div>
                    <div class="contractterm-incialdate" *ngIf="diffs.indexOf('licenseType')!==-1">
                        <div class="text-style">
                            <p><strong>Tipo de Licenciamento</strong> </p>
                        </div>
                        <div class="input-options">
                            <mmp5-fieldset-haruki
                            [formControl]="form.get([5]).get('reasonId')"
                            [key]="'options_licenseType'"
                            [label]="'Selecione o motivo'"
                            [selectOptions]="options"
                            ></mmp5-fieldset-haruki>
                        </div>
                    </div>
                    <div class="contractterm-incialdate" *ngIf="diffs.indexOf('initialDate')!==-1">
                        <div class="text-style">
                            <p><strong>Data início da vigência do contrato</strong> </p>
                        </div>
                        <div class="input-options">
                            <mmp5-fieldset-haruki
                            [formControl]="form.get([2]).get('reasonId')"
                            [key]="'options_initialDate'"
                            [label]="'Selecione o motivo'"
                            [selectOptions]="options"
                            ></mmp5-fieldset-haruki>
                        </div>
                    </div>
                    <div class="contractterm-finaldate" *ngIf="diffs.indexOf('finalDate')!==-1">
                        <div class="text-style">
                            <p><strong>Data final da vigência do contrato</strong> </p>
                        </div>
                        <div class="input-options">
                            <mmp5-fieldset-haruki
                            [formControl]="form.get([3]).get('reasonId')"
                            [key]="'options_finalDate'"
                            [label]="'Selecione o motivo'"
                            [selectOptions]="options"
                            ></mmp5-fieldset-haruki>
                        </div>
                    </div>
                    <div class="module-activation" *ngIf="changedModules">
                        <div *ngIf="changedModules.new.length > 0" class="text-style">
                            <p><strong>Ativação do módulo</strong> </p>
                        </div>
                        <div *ngIf="changedModules" class="module-name">
                            <ng-template ngFor let-module [ngForOf]="changedModules.new">
                                <p><span>{{module.module.name}}</span></p>
                            </ng-template>
                        </div>
                        <div *ngIf="changedModules.disable.length>0" class="text-style">
                            <p><strong>Desativação do módulo</strong> </p>
                        </div>
                        <div *ngIf="changedModules" class="module-name">
                            <ng-template ngFor let-module [ngForOf]="changedModules.disable">
                                <p><span>{{module.module.name}}</span></p>
                            </ng-template></div>
                        <div class="input-options">
                            <mmp5-fieldset-haruki
                                [formControl]="form.get([4]).get('reasonId')"
                                [key]="'options_module'"
                                [label]="'Selecione o motivo'"
                                [selectOptions]="options"
                            ></mmp5-fieldset-haruki>
                        </div>
                    </div>
                    <div class="d-flex mt-2 mb-2">
                        <div class="col-auto ps-0">
                            <div class="checkbox-style">
                                <mmp5-fieldset-haruki
                                    [formControl]="allReason"
                                    [key]="'allReason'"
                                    [type]="'checkbox'"
                                    [value]="false"
                                    [label]="'Utilizar o mesmo motivo para todos os campos acima'">
                                </mmp5-fieldset-haruki>
                            </div>
                        </div>
                    </div>
                    <mmp5-fieldset-haruki
                        [formControl]="setAllReason"
                        [key]="'options_all'"
                        [label]="'Selecione o motivo'"
                        [selectOptions]="options">
                    </mmp5-fieldset-haruki>
                </div>
                <div class="mm-box-footer">
                    <div class="row justify-content-end">
                        <div class="col-auto me-2">
                            <button class="btn btn-outline-dark" (click)="back()">Voltar e continuar editando</button>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary btn-block" [disabled]="form.invalid" (click)="send()">Concluir edição</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
