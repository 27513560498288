import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { LicenceChartData } from "../../interfaces/licence-chart-data.component";
import { DashboardService} from "./dashboard.service";
import { ActiveClientsInterface } from "../../interfaces/active-clients-interface";
import { Globals } from "../../utils/globals";
import { User } from "../../models/user";

@Component ({
    selector: 'mmp5-clientes-licenciamento-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

    activeClients: ActiveClientsInterface
    licenceChart: LicenceChartData
    navbar_items: Array<string> = []
    loggedUser: any;

    constructor (
        private router: Router,
        private dashboardService: DashboardService,
        private user: User,
        private globals: Globals) {

            this.navbar_items = this.globals.globals.navbar_items;
        
            this.licenceChart = {
            'columnNames' : [
                "Licenças Ativas",
                "Dia",
                "MES"   
            ],
            data: [
                
                ['1', 2],
                ['2', 5],
                ['3', 17],
                ['4', 50],
                ['5', 80]
               
            ]
        }


        dashboardService.getClients().subscribe((resp)=> {
            this.activeClients = resp
        })        
    }
    
    ngOnInit(): void {
        this.getLoggedUser()
    }

    getLoggedUser() {
        this.loggedUser = this.user.getUser();
    }

    cadastrarCli() {
        this.router.navigate(['clients', 'form'])
    }


}