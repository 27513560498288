<div class="container">
    <mmp5-breadcrumb></mmp5-breadcrumb>
</div>
<div class="container">

    <div class="row justify-content-center">
        <div class="col-12 col-lg-12">
            <mmp5-wizard-steps
                #wizard
                [steps]="forms"
                [icon]="icon"
                [currentStep]="step">
            </mmp5-wizard-steps>
        </div>
    </div>

    <div *ngIf="step==1" class="row mt-5 mb-4 justify-content-center">
        <div class="col-11 col-lg-8">
            <section class="mm-box-container">
                <header class="mm-box-header">
                    <h6 class="m-0">Dados da Empresa</h6>
                </header>
                <div class="mm-box-content">
                        <div class="container ps-0 pe-0">
                            <div class="row justify-content-center mb-2">
                                <div class="col-12">
                                    <mmp5-fieldset-haruki
                                        [formControl]="companyName"
                                        [key]="'companyName'"
                                        [label]="'Nome do Cliente'">
                                    </mmp5-fieldset-haruki>
                                </div>
                            </div>
                            <div class="row justify-content-center mb-2 ">
                                <div class="col-12">
                                    <mmp5-fieldset-haruki
                                        [formControl]="cnpj"
                                        [key]="'cnpj'"
                                        [label]="'CNPJ'"
                                        [mask]="cnpjMask">
                                    </mmp5-fieldset-haruki>
                                </div>
                            </div>
                            <div class="row justify-content-center mb-1">
                                <div class="col-12">
                                    <mmp5-fieldset-haruki
                                        [formControl]="licenseType"
                                        [key]="'licenciamento'"
                                        [label]="'Tipo de Licenciamento'"
                                        [selectOptions]="licenciamentoOptions">
                                    </mmp5-fieldset-haruki>
                                </div>
                            </div>
                            <div class="row ps-2">
                                <h6>Quantidade de licenças contratadas</h6>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-4">
                                    <p>Minimo de licenças</p>
                                    <mmp5-fieldset-haruki
                                        [formControl]="qtMin"
                                        [key]="'qtMin'"
                                        [type]="'number'"
                                        [label]="'Mínimo de Licenças*'"
                                        [mask]="minus"
                                        [showError]="false">
                                    </mmp5-fieldset-haruki>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <p>Máximo de licenças</p>
                                    <mmp5-fieldset-haruki
                                        [formControl]="qtMax"
                                        [label] = "'Maxímo de Licenças*'"
                                        [key]="'qtMax'"
                                        [type]="'number'"
                                        [mask]="minus"
                                        (change)="quantMax()"
                                        [showError]="false">
                                    </mmp5-fieldset-haruki>
                                </div>
                                <div class="col-12 col-lg-4 d-flex align-items-center">
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <mmp5-fieldset-haruki
                                                [formControl]="autoPartialBlock"
                                                [type]="'checkbox'"
                                                [key]="'checkbox'"
                                                [checked]="true"
                                                [label]="'Efetua bloqueio automático?'">
                                            </mmp5-fieldset-haruki>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ps-2" id="alertQuant" #alertQuant>
                                    <p class='form-text mb-0'> O mínimo de licenças deverá ser menor que o máximo </p>
                            </div>
                            <div class="row ps-2 mt-1">
                                <h6>Vigência do contrato</h6>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <mmp5-fieldset-haruki
                                        [formControl]="initialDate"
                                        [type]="'date'"
                                        [label]="'Inicio*'"
                                        [showError] = "false"
                                        [key]="'initialDate'"
                                        [mask]="dateMask">
                                    </mmp5-fieldset-haruki>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <mmp5-fieldset-haruki
                                        [formControl]="finalDate"
                                        [type]="'date'"
                                        [label]="'Término*'"
                                        [key]="'initialDate'"
                                        [showError] = "false"
                                        [mask]="dateMask"
                                        (blur)="dateMax()">
                                    </mmp5-fieldset-haruki>
                                </div>
                            </div>
                            <div class="row ps-2 mt-1">
                                <h6>Observação</h6>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <mmp5-fieldset-haruki
                                        [formControl]="observations"
                                        [label]="'Observação'"
                                        [key]="'observations'"
                                    ></mmp5-fieldset-haruki>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mm-box-footer">
                        <div class="row justify-content-between">
                            <div class="col-4">
                              <div class="d-grid gap-2">
                                <button class="btn btn-outline-dark btn-block" (click)="cancel()">Cancelar</button>
                              </div>

                            </div>
                            <div class="col-4">
                              <div class="d-grid gap-2">
                                <button class=" btn btn-primary btn-block" [disabled]="formClientData.invalid" (click)="sendForm()">Próximo</button>
                              </div>

                            </div>
                        </div>
                    </div>
                <div>

                </div>
            </section>
        </div>
    </div>

    <div *ngIf="step==2" class="row mt-5 justify-content-center">
        <div class="col-12 col-lg-8">
            <section class="mm-box-container">
                <header class="mm-box-header">
                    <h6 class="m-0">Ponto focal do projeto</h6>
                </header>
                <div class="mm-box-content">
                    <div class="container ps-0 pe-0">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="alert alert-secondary">
                                      <strong>Atenção: </strong>Lembre-se que o e-mail de contato deve ser uma chave de e-mail, para que mais de uma pessoa possa ser contatada quando houver necessidade.
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-2">
                            <div class="col-12">
                                <mmp5-fieldset-haruki
                                    [formControl]="name"
                                    [key]="'name'"
                                    [label]="'Nome do contato responsável*'">
                                </mmp5-fieldset-haruki>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-2 ">
                            <div class="col-12">
                            <mmp5-fieldset-haruki
                                    [formControl]="email"
                                    [key]="'e-mail'"
                                    [label]="'E-mail do contato responsável'">
                            </mmp5-fieldset-haruki>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-2">
                            <div class="col-12">
                                <mmp5-fieldset-haruki
                                    [formControl]="phone"
                                    [key]="'phone'"
                                    [showError]="false"
                                    [label]="'Telefone do contato responsável'"
                                    [type]="'phone'">
                                </mmp5-fieldset-haruki>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mm-box-footer">
                    <div class="row justify-content-between">
                        <div class="col-4">
                          <div class="d-grid gap-2">
                            <button class="btn btn-outline-dark btn-block" (click)="cancel()">Cancelar</button>
                          </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-6">
                                  <div class="d-grid gap-2">
                                    <button class=" btn btn-outline-dark btn-block" (click)="backForm()">Anterior</button>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="d-grid gap-2">
                                    <button class=" btn btn-primary btn-block" (click)="sendForm()" [disabled]="formProjectFocal.invalid">Próximo</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </section>
        </div>
    </div>

    <div *ngIf="step==3" class="row mt-5 justify-content-center">
        <div class="col-12 col-lg-8">
                <section class="mm-box-container">
                        <header class="mm-box-header">
                            <h6 class="m-0">Contatos para Relatórios</h6>
                        </header>
                        <div class="mm-box-content">
                            <div class="container ps-0 pe-0">
                                <div class="row">
                                    <div [ngClass]="{'col-11': deleteEmails, 'col-12': !deleteEmails}">
                                        <div>
                                            <p>
                                            Preencha os contatos que receberão via e-mail e selecione qual a periodicidade que deverão ser enviados esses relatórios.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div [ngClass]="{'col-11': deleteEmails, 'col-12': !deleteEmails}">
                                        <div class="alert alert-secondary">
                                            Caso seja necessário, é possivel cadastrar mais e-mails para recebimento desses relatórios.
                                        </div>
                                    </div>
                                </div>
                                <div emailResp>
                                        <ng-template ngFor let-group [ngForOf]="reportEmailsf.controls" let-i="index">
                                            <div class="row">
                                                <div [ngClass]="{'col-11': deleteEmails, 'col-12': !deleteEmails}">
                                                    <hr [ngClass]="{'hr-report': true, 'mt-2': true,  'mb-2': true}" *ngIf="i!=0">
                                                    <mmp5-fieldset-haruki
                                                        [formControl]="group.get('email')"
                                                        [label]="'E-mail do contato responsável*'">
                                                    </mmp5-fieldset-haruki>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div [ngClass]="{'col-11': deleteEmails, 'col-12': !deleteEmails ,'mt-2': true}">
                                                    <mmp5-fieldset-haruki
                                                        [formControl]="group.get('type')"
                                                        [key]="'periocidade'"
                                                        [label]="'Periodicidade do envio'"
                                                        [selectOptions]="periocidadeOpt">
                                                    </mmp5-fieldset-haruki>
                                                </div>
                                                <div class="col-auto" *ngIf="deleteEmails">
                                                    <button class="btn-as-link" (click)="removeEmail($event, i)">
                                                        <!-- <i class="fa fa-trash"></i> -->
                                                        <mmp5-mm-icons [icon]="['fa-solid','fa-trash-can']"></mmp5-mm-icons>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    <div [ngClass]="{'row justify-content-end mt-1':true, 'addEmail': deleteEmails}">
                                        <div class="col-4">
                                            <a class="form-text text-right mb-1" (click)="addEmail($event)" href="">Adicionar novo e-mail</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mm-box-footer">
                            <div class="row justify-content-between">
                                <div class="col-4">
                                  <div class="d-grid gap-2">
                                    <button class="btn btn-outline-dark btn-block" (click)="cancel()">Cancelar</button>
                                  </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-6">
                                          <div class="d-grid gap-2">
                                            <button class=" btn btn-outline-dark btn-block" (click)="backForm()">Anterior</button>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="d-grid gap-2">
                                            <button class=" btn btn-primary btn-block" (click)="sendForm()" [disabled]="reportEmailsf.invalid">Próximo</button>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                    </section>
        </div>
    </div>

    <div *ngIf="step==4" class="row mt-5 justify-content-center">
            <div class="col-12 col-lg-12">
                    <section class="mm-box-container">
                            <header class="mm-box-header">
                                <h6 class="m-0">Módulos contratados</h6>
                            </header>
                            <div class="mm-box-content">
                                <div class="container ps-0 pe-0">
                                    <div class="row">
                                        <div class="col-12">
                                            <p>
                                                Ative quais módulos este cliente está contratando e indique uma data início e final caso seja necessário.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mm-list-modules
                                                #modules
                                                [type]="'enable'"
                                                [activeClientsModules]="activeModules"
                                                [disable]="disable"
                                                (update)="activeModules = $event"
                                                (valid)="setInvalidForm($event)">
                                            </mm-list-modules>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="errorsFinalModules.length>0">
                                        <div class="col-12">
                                            <mm-alert [type]="'danger'" [text]="'Data final dos módulos: '+errorsFinalModules+', superior á data final do contrato.'"></mm-alert>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="errorsInitialModules.length>0">
                                        <div class="col-12">
                                            <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorsInitialModules+', inferior á data inícial do contrato.'"></mm-alert>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="errorsFinalClient.length>0">
                                        <div class="col-12">
                                            <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorsFinalClient+', superior á data final do contrato.'"></mm-alert>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="errorsInitialClient.length>0">
                                        <div class="col-12">
                                            <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorsInitialClient+', inferior á data inícial do contrato.'"></mm-alert>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="errorsSameDate.length>0">
                                        <div class="col-12">
                                            <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorsSameDate+', é a mesma da data final.'"></mm-alert>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="errorDateModules.length>0">
                                        <div class="col-12">
                                            <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorDateModules+', é superior á data final.'"></mm-alert>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mm-box-footer">
                                <div class="row justify-content-between">
                                    <div class="col-2">
                                      <div class="d-grid gap-2">
                                        <button class="btn btn-outline-dark btn-block" (click)="cancel()">Cancelar</button>
                                      </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-6">
                                              <div class="d-grid gap-2">
                                                <button class="btn btn-outline-dark btn-block" (click)="backForm()">Anterior</button>
                                              </div>
                                            </div>
                                            <div class="col-6">
                                              <div class="d-grid gap-2">
                                                <button class="btn btn-primary btn-block" (click)="sendForm()">Concluir cadastro</button>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                        </section>
            </div>
    </div>

</div>
