import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { RecaptchaModule } from 'ng-recaptcha';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { APP_INITIALIZER } from '@angular/core';

import { JwtModule } from '@auth0/angular-jwt';

import { AppConfig } from '../app.config';

import { AuthService } from '../services/auth.service';
import { ClientService } from '../services/client.service';

import { User } from '../models/user';

import { AlertControllerModule, AlertController } from 'mm-ui';

import { AuthGuard } from '../guards/auth.guard';
import { SigninGuard } from '../guards/signin.guard';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginRoutingModule } from '../components/login/login.component-routing.module';
import { DashboardComponentModule } from '../components/dashboard/dashboard.component.module';
import { LoginGuard } from '../guards/login-guard';
import { ErrorPageModule } from '../components/error-page/error-page.module';
import { ClientsModule } from '../components/clients/clients-module';
import { LoginComponent } from '../components/login/login.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    AlertControllerModule,
    HttpClientModule,
    RecaptchaModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    LoginRoutingModule,
    DashboardComponentModule,
    ErrorPageModule,
    ClientsModule
  ],
  declarations: [],
  providers: [
    AuthService,
    ClientService,
    User,
    AuthGuard,
    SigninGuard,
    LoginGuard,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useFactory: siteKey,
      deps: [AppConfig]
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'pt-BR'
    }
  ]
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor (
    private translate: TranslateService,
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
    this.translate.use('pt-Br')
  }
}
export function initConfig(config: AppConfig) { return () => config.load(); }
export function siteKey() { return {siteKey: AppConfig.data['recaptcha']['key']}; }

