import { Component, OnInit, ViewContainerRef, AfterViewInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, FormGroup, UntypedFormArray } from '@angular/forms';
import { FieldsetHarukiSelectOption, AlertController, LoadingSpinner } from 'mm-ui';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Client } from '../../../interfaces/cliente-interface';
import { ClientEditMotiveService } from './client-editmotive.service';
import { ReasonInterface } from './reason-interface';
import { ListModuleService } from '../client-form/list-modules/list-module.service';
import { ModuleInterface } from '../../../interfaces/modules/module-interface';
import { ClientEditService } from '../client-edit/client-edit.service';



@Component({
    selector: 'mm-client-editomotive',
    templateUrl: './client-editmotive.component.html',
    styleUrls: ['./client-editmotive.component.scss']

})

export class ClientEditMotiveComponent implements OnInit, AfterViewInit {

    forms: Client
    diffs: Array<string>
    reasons: Array<ReasonInterface> = []
    options: Array<FieldsetHarukiSelectOption>
    modules: Array<ModuleInterface>
    changedModules: any
    config: any
    form: UntypedFormArray
    f:any
    allReason = new UntypedFormControl('');
    setAllReason = new UntypedFormControl('');
    disable : boolean = false

    fieldOptions = new UntypedFormControl('');


    constructor(private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private vcr: ViewContainerRef,
        private loadingSpinner: LoadingSpinner,
        private editMotiveService: ClientEditMotiveService,
        private listModulesService: ListModuleService,
        private clientEditService: ClientEditService,
        private router: Router,
        private alertController: AlertController) {

            this.loadingSpinner.setViewContainerRef(vcr)
            this.form = this.formBuilder.array([
                this.formBuilder.group({
                    field: "qtMax",
                    reasonId: new UntypedFormControl('')
                }),
                this.formBuilder.group({
                    field: "qtMin",
                    reasonId: new UntypedFormControl('')
                }),
                this.formBuilder.group({
                    field: "initialDate",
                    reasonId: new UntypedFormControl('')
                }),
                this.formBuilder.group({
                    field: "finalDate",
                    reasonId: new UntypedFormControl('')
                }),
                this.formBuilder.group({
                    field: "modules",
                    reasonId: new UntypedFormControl('')
                }),
                this.formBuilder.group({
                    field: "licenseType",
                    reasonId: new UntypedFormControl('')
                }),
            ])

            this.editMotiveService.getReasons().subscribe(resp => {
                this.reasons = resp.content
                this.options = []
                this.reasons.forEach(r => {
                    this.options.push({
                        label: r.description,
                        value: r.id
                    })
                })
            })
        }


    ngAfterViewInit() {

    }

    setReasonsBlock(){
        this.allReason.valueChanges.subscribe(resp => {
            if(resp) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        })
    }

    //licenceType
    //qtMin
    //

    ngOnInit() {
        this.forms = JSON.parse(localStorage.getItem('forms'));
        this.diffs = JSON.parse(localStorage.getItem('diffs'));
        this.changedModules = JSON.parse(localStorage.getItem('modules'))

        if(this.forms == null || this.diffs == null){
            this.router.navigate(['/clients','list'])
        }

        this.listModulesService.getModules(true).subscribe(resp => {
            this.modules = resp.content
        })

        this.allReason.valueChanges.subscribe((e)=> {
            if(e){
              this.disable = true
            } else {
              this.disable = false
            }
        })

        this.setReasonsBlock();
    }

    send(){

        if (this.disable){
            let val = this.setAllReason.value;

            Object.keys(this.form.controls).forEach(e => {
                if(this.diffs.indexOf(this.form.controls[e].controls.field.value) != -1)
                this.form.controls[e].controls.reasonId.setValue(val)
            })
        }

        let reasonForm = this.form.value.filter(e => {
            if(e.reasonId !=""){
                return e
            } else {
                return false
            }
        })

        let finalForm = Object.assign({reasons: reasonForm}, this.forms)

        this.loadingSpinner.open()
        this.clientEditService.sendForm(finalForm.id, finalForm).subscribe(response => {
            this.loadingSpinner.close()
            this.router.navigate(['/clients','view',finalForm.id, {altered:true}])
            this.clearStorage()
        }, err => {
            this.loadingSpinner.close()
            let errors: Array<string> = []
            if(err.error.errors){
                err.error.errors.map(e => {
                    if(e == "finalDate: must be greater than initialDate"){
                        errors.push('Data final deve ser superior à data inicial');
                    }
                    if(e == "qtMax: must be greater than qtMin") {
                        errors.push('Quantidade de licenças máxima deve ser maior do que a mínima')
                    }
                })
            }

            this.config = {
                title: 'Erro alteração de cliente',
                message: `<p>Não foi possivel alterar cliente</p> ${errors.map(e => {return e+" "})}` ,
                buttons: [
                    {
                        text: 'Voltar a alteração de dados do cliente',
                        handler: ()=> {
                            this.clearStorage()
                            this.alertController.close()
                            this.router.navigate(['/clients','edit',finalForm.id])
                        },
                        type: 'primary'
                    }
                ]
            }

            this.alertController.configure(this.config)

            this.alertController.open()
      })

    }

    clearStorage(){
        localStorage.removeItem('diffs')
        localStorage.removeItem('forms')
        localStorage.removeItem('modules')
    }

    back() {
        this.clearStorage();
        this.router.navigate(['clients','edit', this.forms.id])
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.loadingSpinner.clearVcr();
    }
}
