import { NgbDatepickerConfig, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

const toInteger = (value: any): number => parseInt(`${value}`, 10);
const isNumber = (value: any): boolean => !isNaN(toInteger(value));
const padNumber = (value: number) => isNumber(value) ? `0${value}`.slice(-2) : '';

@Injectable()

export class NgbDateBRParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {

    if (value) {
        const dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return {year: toInteger(dateParts[0]), month: null, day: null};
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return {year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null};
            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return {year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0])};
            }
        }
        return null;
    }

    format (date: NgbDateStruct): string {
        let stringDate = '';
        if (date) {
            stringDate += isNumber(date.year) ? padNumber(date.year) + '-' : '';
            stringDate += isNumber(date.month) ? padNumber(date.month) + '-' : '';
            stringDate += date.day;
        }
        return stringDate;
    }
}
