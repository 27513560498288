<div class="container">
  <div class="container-border">
    
    <div>
      <h6>Desativação do Módulo [nome do módulo que será desativado]</h6>
      <p> 
        O módulo [Nome do módulo] está ativo para os clientes listados abaixo. Ele será desativado para visualização somente após listar um motivo para justificar sua desativação em cada cliente ou um mesmo motivo que será aplicado para todos os clientes.
      </p>  
      <hr>
    </div>
    
    <div class="text-style">
      <p><strong>Desabilitar este módulo para o cliente: Nome do Cliente AAA</strong> </p>
    </div>
    
    <mmp5-fieldset-haruki
    [formControl]="fieldOptions"
    [key]="'optionsAAA'"
    [label]="'optionsAAA'"
    [selectOptions]="optionsAAA"
    ></mmp5-fieldset-haruki>
    
    <div class="text-style">
      <p><strong>Desabilitar este módulo para o cliente: Nome do Cliente BBB</strong> </p>
    </div>
    
    <mmp5-fieldset-haruki
    [formControl]="fieldOptions"
    [key]="'optionsBBB'"
    [label]="'optionsBBB'"
    [selectOptions]="optionsBBB"
    ></mmp5-fieldset-haruki>
    
    
    <div class="text-style">
      <p><strong>Desabilitar este módulo para o cliente: Nome do Cliente BBB</strong> </p>
    </div>
    
    <mmp5-fieldset-haruki
    [formControl]="fieldOptions"
    [key]="'optionsCCC'"
    [label]="'optionsCCC'"
    [selectOptions]="optionsCCC"
    ></mmp5-fieldset-haruki>
    
    <div class="container-border cborder-style">
      <div class="box-flex">
        <div class="checkbox-style">
          <input type="checkbox" id="x" name="feature" value="x" />
        </div>
        <div>
          <p>Utilizar o mesmo motivo para todos os campos acima</p>
        </div>
      </div>
      <mmp5-fieldset-haruki
      [formControl]="fieldOptions"
      [key]="'options'"
      [label]="'Selecione o motivo'"
      [selectOptions]="options"
      ></mmp5-fieldset-haruki>
    </div>
    
  </div>
</div>