import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: 'mm-autosearch',
    templateUrl: './autosearch.component.html',
    styleUrls: ['./autosearch.component.scss']
})
export class AutoSearchComponent implements OnInit, OnDestroy {
    
    @Input() value:string = '';
    @Output() onTyping = new EventEmitter<string>();
    debounce: Subject<string> = new Subject<string>();
    
    
    ngOnInit(): void {

        this.debounce
            .pipe(debounceTime(300))
            .subscribe(filter => this.onTyping.emit(filter));
            
        
    }
    
    ngOnDestroy(): void {
        this.debounce.unsubscribe();
    }


}