<div class="yellow">
    <div class="margins">
        <section class="mm-box-container card-login">
            <div class="mm-box-header">
                <div class="row justify-content-center mb-2">
                    <div class="col-auto">
                        <img class="logo" class="img-responsive" src="../../assets/mmp5.png">
                    </div>
                </div>
                <div class="row justify-content-center mb-2">
                    <div class="col-12 text-center">
                        <p class="mb-0">Esqueci minha senha</p>
                    </div>
                </div>
            </div>
            <div class="mm-box-content">
                <div id="form">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-12 mb-1">
                                <p>
                                    Informe o e-mail cadastrado na plataforma e nós o ajudaremos a redefinir uma nova
                                    senha
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-2">
                                <mmp5-fieldset-haruki [formControl]="email" [key]="'email'" [label]="'E-mail'">
                                </mmp5-fieldset-haruki>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-12">
                                <button (click)="sendReset('resetbyemail')" [disabled]="form.invalid" class="btn btn-secondary btn-block">Redefinir
                                    minha senha</button>
                            </div>
                            <div *ngIf="error" class="col-12 mt-2">
                                <div class="alert alert-danger">
                                    Não foi possivel enviar e-mail de alteração de senha
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="disabled" id="sended">
                    <div class="row">
                        <div class="col-12 mb-1">
                            <div class="alert alert-success">
                                <div class="row justify-content-center">
                                    <div class="col-auto mb-1">
                                        <!-- <i class="fa fa-envelope"></i> -->
                                        <mmp5-mm-icons [icon]="['fa-solid','fa-envelope']"></mmp5-mm-icons>
                                    </div>
                                </div>
                                Enviamos um e-mail com instruções de como redefinir sua senha para {{email.value}}. Se
                                você não encontrar o e-mail na sua caixa de entrada, verifique a lixeira ou a pasta de
                                spam.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="sended">
                    <div class="col-12">
                        <a routerLink="/login" class="btn btn-secondary btn-block">Voltar</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
