import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { AppConfig } from '../app.config';
import { Client } from '../interfaces/cliente-interface';
import { Module } from '../models/module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable()
export class ClientService {

  apiVersion = `v${AppConfig.data['apiVersion']}`;
  endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`;
  endpointAlice = `${AppConfig.data['endpointAlice']}`;
  endpointUploadMedia = `${AppConfig.data['services']['uploadMedia']}/${this.apiVersion}`;
  endpointFlowsurvey = `${AppConfig.data['services']['flowsurvey']}/${this.apiVersion}`;

  clientList: Array<Client> = []

  constructor(private http: HttpClient, private router: Router) { }

  all(page: number, order: string = '', query: string = null, size: number): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/clients?page=${page}&size=${size}&sort=${order}&query=${query || ''}`);
  }

  get(id: string): Observable<Client> {
    return this.http.get<Client>(`${this.endpoint}/clients/${id}`);
  }

  update(client) {
    return this.http.put<Client>(`${this.endpoint}/clients/${client.id}.json`, { client });
  }

  startUp(data) {
    /*
    const clientStart = {
      client: {
        name: data.name,
        email: data.email,
        password: data.passwords.password,
        password_confirmation: data.passwords.password_confirmation
      }
    };
    */
    const clientStart = data;
    return this.http.post<any>(`${this.endpoint}/clients/startup.json`, clientStart);
  }

  /* fake methods for modules */
  modules(id: string): Observable<any> {
    return of({
      active: [{ name: 'modulo 1', id: 1, startAt: '10/01/2017', endAt: '20/12/2018' }, { name: 'modulo 2', id: 2 }],
      inactive: [{ name: 'modulo 3', id: 3 }, { name: 'modulo 4', id: 4 }],
    });
  }

  toggleModule(moduleData: any) {
    return of(moduleData);
  }

  clientPaginated(page, order, query, size) {

    return this.all(page, order, query, size)
  }

  allClients() {

    return this.http.get<any>(`${this.endpoint}/clients?unpaged=true&name=`)
  }

  totalBlock(clientId, reasonId) {

    return this.http.post<any>(`${this.endpoint}/clients/total_block`, { "clientId": clientId, "reasonId": reasonId })

  }

  totalUnblock(clientId, reasonId) {

    return this.http.post<any>(`${this.endpoint}/clients/total_release`, { "clientId": clientId, "reasonId": reasonId })

  }

  cleanCache(clientId) {
    return this.http.get<any>(`${this.endpoint}/admin/clear/cache/${clientId}`)
  }

  reindexClientUsers(clientId) {
    return this.http.get<any>(`${this.endpoint}/admin/reindex/users/${clientId}`)
  }

  reindexAllClientUsers() {
    return this.http.get<any>(`${this.endpoint}/admin/reindex/users_all`)
  }

  reindexClientPageAndMedias(clientId) {
    return this.http.get<any>(`${this.endpoint}/admin/reindex/pages-medias/${clientId}`)
  }

  reindexAllClientMedias() {
    return this.http.post<any>(`${this.endpoint}/admin/reindex/medias/`, null)
  }

  deleteMediasDefinitively(id) {
    return this.http.post<any>(`${this.endpoint}/clients/delete_data_definitely/${id}`, null)
  }

  createEmbeddingIndex(id: string) {
    return this.http.get(`${this.endpoint}/admin/reindex/embedding/${id}`)
  }

  createEmbeddingFlowsurvey() {
    return this.http.get(`${this.endpointFlowsurvey}/flowsurvey/vetorial/index`, { headers: { "useReportToken": "true" } });
  }

  getAliceKeyValueConfig(id: string) {
    return this.http.get<any>(`${this.endpointAlice}/config/key-config/${id}`)
      .pipe(map((list) => list.sort((a, b) => {
        if (a.type === 'SYSTEM_MESSAGE') {
          return -4
        } else if (a.type === 'MODEL') {
          return -1
        } else if (a.type === 'MODEL_EMBEDDING') {
          return -2
        } else if (a.type === 'DOCUMENT_SPLITTER_SEGMENT_SIZE') {
          return -3
        } else {
          return 0
        }
      })))
  }

  aliceDeleteKeyConfig(id: string) {
    return this.http.delete<any>(`${this.endpointAlice}/config/key-config/${id}`)
  }

  saveAliceKeyValueConfig(configs: any, id: string) {
    const removeEmptyExternalConnectorConfig = configs?.map(config => ({
      ...config,
      externalConnector: config?.externalConnector?.type != null ? config.externalConnector : null
    }))

    return this.http.put<any>(`${this.endpointAlice}/config/key-config/${id}`, removeEmptyExternalConnectorConfig);
  }

  aliceGetGoogleConfig() {
    return this.http.get<any>(`${this.endpointAlice}/v1/google_auth`, { headers: { "useReportToken": "true" } });
  }

  aliceSaveGoogleConfig(config) {
    return this.http.post<any>(`${this.endpointAlice}/v1/google_auth`, config, { headers: { "useReportToken": "true" } });
  }

  getUploadMediaMaxFileUploadConfig() {
    return this.http.get<any>(`${this.endpointUploadMedia}/max-file-size-configs`, { headers: { "useReportToken": "true" } });
  }

  saveUploadMediaMaxFileUploadConfig(config: any) {
    return this.http.post<any>(`${this.endpointUploadMedia}/max-file-size-configs`, config, { headers: { "useReportToken": "true" } });
  }

  gStorageGetConfig() {
    return this.http.get<any>(`${this.endpointUploadMedia}/google_storage_config`, { headers: { "useReportToken": "true" } });
  }

  gStorageSaveConfig(config) {
    return this.http.post<any>(`${this.endpointUploadMedia}/google_storage_config`, config, { headers: { "useReportToken": "true" } });
  }
}
