import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Breadcrumb } from "../../../interfaces/breadcrumb";
import { ClientService } from "../../../services/client.service";
import { Client } from "../../../interfaces/cliente-interface";


@Component({
    selector: 'mm-client-created',
    templateUrl:'client-created.component.html',
    styleUrls:['client-created.component.scss']
})

export class ClientCreatedComponent implements OnInit{
    breadcrumb: Array<Breadcrumb>;
    client: Client
    id: string

    constructor(private clientService: ClientService,
                private route: ActivatedRoute) {
                    this.id = this.route.snapshot.params.id;
                    this.clientService.get(this.id).subscribe(response => {this.client = response})                                        
                }
                
    ngOnInit(){
    
    }
   
    back() {

    }


}