import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Client } from "../../../interfaces/cliente-interface";
import { ClientService } from "../../../services/client.service";
import { Globals } from "../../../utils/globals";

@Injectable({
    providedIn: 'root'
})

export class ClientListResolver {

    page: number;
    size: number ;
    order: string;
    query: string ;
    last: boolean;

    constructor(
        private clientService: ClientService,
        private globals: Globals
    ){
        this.page = this.globals._globals.pagination.page;
        this.size = this.globals._globals.pagination.size;
        this.order = this.globals._globals.pagination.sort;
        this.query = this.globals._globals.pagination.query;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return this.clientService.clientPaginated(this.page, this.order, this.query, this.size)
    }

}