<div class="purple">
    <div class="margins">
        <section class="mm-box-container card-login">
            <div class="mm-box-header">
                <div class="row justify-content-center mb-2">
                    <div class="col-auto">
                        <img class="logo" class="img-responsive" src="../../assets/mmp5.png">
                    </div>
                </div>
                <div class="row justify-content-center mb-2">
                    <div class="col-12 text-center">
                        <p class="mb-0">Crie uma senha para ter acesso ao sistema. </p>
                    </div>
                </div>
            </div>
            <div class="mm-box-content">
                <div class='row' *ngIf="activation">
                    <div class="col-12">
                        <div class="alert alert-success">
                            Usuário ativado com sucesso!
                        </div>
                    </div>
                </div>
                <div class='row' *ngIf="errorActivation">
                    <div class="col-12">
                        <div class="alert alert-danger">
                            Não foi possível ativar sua conta, contate o suporte.
                        </div>
                    </div>
                </div>
                <form [formGroup]="formEmailPass">
                    <div>
                        <div class="row">
                            <div class="col-12 mb-2">
                                <mmp5-fieldset-haruki [formControl]="password" [key]="'password'" [label]="'Senha'"
                                    [type]="'password'" [showError]="false" [tooltip]="'Mínimo de 8 caracteres entre letras e números'">
                                </mmp5-fieldset-haruki>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-2">
                                <mmp5-fieldset-haruki [formControl]="confirmPassword" [key]="'confirmPassword'"
                                    [showError]="false" [label]="'Digite novamente a sua senha'" [type]="'password'">
                                </mmp5-fieldset-haruki>
                            </div>
                        </div>

                        <div class="row mb-1">
                            <div class="col-12">
                                <button (click)="changePass()" [disabled]="formEmailPass.invalid" class="btn btn-secondary btn-block">Redefinir
                                    minha senha</button>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div id="alert" class="alert alert-danger disabled">
                                    As senhas não coicidem
                                </div>
                                <div *ngIf="error" class="alert alert-danger">
                                    Não foi possivel alterar a senha, contate o suporte.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="disabled" id="sended">
                    <div class="row">
                        <div class="col-12 mb-1">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="sended">
                    <div class="col-12">
                        <a routerLink="/login" class="btn btn-secondary btn-block">Voltar</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>