import { Component, ViewChild, ElementRef, HostListener, ViewContainerRef, Directive, OnDestroy } from "@angular/core";
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from "@angular/forms";
import { FieldsetHarukiSelectOption, AlertController, LoadingSpinner } from "mm-ui";
import { Breadcrumb } from "../../../interfaces/breadcrumb";
import { ReportEmail } from "./report-email";
import { Router } from "@angular/router";
import { ClientFormService } from "./client-form.service";
import { Client } from "../../../interfaces/cliente-interface";
import { validateCNPJval } from "../../../services/custom-validators";
import * as moment from 'moment';
import { ListModulesComponent } from "./list-modules/list-modules.component";
import { ModulesInterface } from "../../../interfaces/modules/modules-interface";
import { ClientEditService } from "../client-edit/client-edit.service";
import { Utils } from "../../../utils/utils";
import { Store } from "@ngrx/store";
import { ClientFormState } from "../../../clientForm.state";
import * as ClientFormActions from "./../../../actions/client-form.actions";
import { first, debounceTime } from "rxjs/operators";
import { dateMask, cnpjMask, minus } from "../../../utils/masks";


@Component({
  selector: 'mm-client-form',
  templateUrl: 'client-form.component.html',
  styleUrls: ['client-form.component.scss']
})

export class ClientFormComponent {

  show = false;
  errorsFinalModules: Array<string> = []
  errorsInitialModules: Array<string> = []
  errorsFinalClient: Array<string> = []
  errorsInitialClient: Array<string> = []
  errorsSameDate: Array<string> = []
  errorDateModules: Array<string> = []
  errorDateModulesFinal: Array<string> = []

  breadcrumb: Array<Breadcrumb>;

  cnpj = new UntypedFormControl('', [Validators.required, validateCNPJval]);
  licenseType = new UntypedFormControl('', [Validators.required]);
  companyName = new UntypedFormControl('', [Validators.required, Validators.minLength(2)]);
  qtMin = new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.maxLength(6)]);
  qtMax = new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.maxLength(6)]);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  reportEmails: Array<ReportEmail> = [{
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    type: new UntypedFormControl('', [Validators.required]
    )
  }];
  autoPartialBlock = new UntypedFormControl(true);
  initialDate = new UntypedFormControl('', [Validators.required, Validators.pattern('^([0]?[1-9]|[1|2][0-9]|[3][0|1])[/]([0]?[1-9]|[1][0-2])[/]([0-9]{4}|[0-9]{2})$')]);
  finalDate = new UntypedFormControl('', [Validators.required, Validators.pattern('^([0]?[1-9]|[1|2][0-9]|[3][0|1])[/]([0]?[1-9]|[1][0-2])[/]([0-9]{4}|[0-9]{2})$')]);
  observations = new UntypedFormControl('');
  name = new UntypedFormControl('', [Validators.required]);
  phone = new UntypedFormControl('', [Validators.required]);
  firstsend: boolean = false
  client: Client;

  icon: Array<string>

  config: any

  deleteEmails: boolean = false;

  formClientData = new UntypedFormGroup({
    companyName: this.companyName,
    cnpj: this.cnpj,
    licenseType: this.licenseType,
    qtMax: this.qtMax,
    qtMin: this.qtMin,
    autoPartialBlock: this.autoPartialBlock,
    initialDate: this.initialDate,
    finalDate: this.finalDate,
    observations: this.observations
  })

  reportEmailsf: UntypedFormArray

  formProjectFocal = new UntypedFormGroup({
    name: this.name,
    email: this.email,
    phone: this.phone
  })

  dateMask = dateMask;
  cnpjMask = cnpjMask;
  minus = minus;

  @ViewChild('alertQuant', { static: true }) alertQuant: ElementRef

  @ViewChild('modules') modules: ListModulesComponent
  activeModules: ModulesInterface[];

  @HostListener('click', ['$event.target'])
  onclick(btn) {

  }

  step: number;
  forms: Array<{}>;

  licenciamentoOptions: Array<FieldsetHarukiSelectOption> = [
    { label: 'Usuários nominados', value: 'AVAILABLE_USERS' },
    { label: 'Usuários simultâneos', value: 'SIMULTANEOUS_AUTH' }
  ]

  periocidadeOpt: Array<FieldsetHarukiSelectOption> = [
    { label: 'Diário', value: 'DAILY' },
    { label: 'Semanal', value: 'WEEKLY' },
    { label: 'Mensal', value: 'MONTHLY' },
  ]

  disable: boolean = false

  constructor(private formBuilder: UntypedFormBuilder,
    private vcr: ViewContainerRef,
    private alertController: AlertController,
    private loadingSpinner: LoadingSpinner,
    private router: Router,
    private store: Store<ClientFormState>,
    private clientFormService: ClientFormService,
    private clientEditService: ClientEditService,
    private utils: Utils) {

    alertController.setViewContainerRef(vcr);
    loadingSpinner.setViewContainerRef(vcr);

    this.forms = [
      'Dados da empresa',
      'Ponto focal do projeto',
      'Contatos para relatórios',
      'Módulos contratados'
    ];

    this.step = 1;

    this.icon = [
      'icon icon-wizard-1-a',
      'icon icon-wizard-1-b',
      'icon icon-wizard-1-c',
      'icon icon-wizard-1-d'
    ]

    this.reportEmailsf = this.formBuilder.array([new UntypedFormGroup({
      email: this.reportEmails[0].email,
      type: this.reportEmails[0].type
    })])

    let formStored = store.select('clientForm');
    formStored
      .pipe(
        first()
      )
      .subscribe(({ companyName, autoPartialBlock, cnpj, email, finalDate, initialDate, licenseType, modules, name, observations, phone, qtMax, reportEmails, qtMin, url }) => {

        this.formClientData.setValue({
          companyName,
          cnpj,
          licenseType,
          qtMax,
          qtMin,
          autoPartialBlock,
          initialDate,
          finalDate,
          observations
        })
        this.name.setValue(name)
        this.email.setValue(email),
          this.phone.setValue(phone)
        this.formProjectFocal.setValue({
          name,
          email,
          phone
        })
        if (reportEmails.length > 0) {
          this.reportEmailsf = this.formBuilder.array([])
          this.deleteEmails = true;
          reportEmails.forEach(rep => {
            this.reportEmailsf.push(this.formBuilder.group(rep))
          })
        }
        this.activeModules = modules
      })

    this.getFormChange();
  }

  getFormChange() {
    this.formClientData.valueChanges

      .subscribe(resp => {
        this.storeFormChange()
      })
    this.reportEmailsf.valueChanges
      .pipe(debounceTime(500))
      .subscribe(resp => {
        this.storeFormChange();
      });
    this.formProjectFocal.valueChanges
      .pipe(debounceTime(500))
      .subscribe(resp => {
        this.storeFormChange();
      });
  }

  storeFormChange() {
    let mod = this.activeModules
    let data = {
      ...this.formClientData.value,
      ...{ reportEmails: this.reportEmailsf.value },
      ...this.formProjectFocal.value,
      ...{ modules: mod }
    }
    this.storeFormData(data)
  }

  storeFormData(data) {
    this.store.dispatch(new ClientFormActions.SetForm(data))
  }

  sendForm() {
    if (this.step == 4) {

      if (moment(this.initialDate.value, "DD/MM/YYYY").format("YYYY-MM-DD") != "Invalid date") {
        this.initialDate.setValue(moment(this.initialDate.value, "DD/MM/YYYY").format("YYYY-MM-DD"))
        this.finalDate.setValue(moment(this.finalDate.value, "DD/MM/YYYY").format("YYYY-MM-DD"))
      }

      let mod: Array<ModulesInterface> = this.modules.getModulesActive()

      this.errorsFinalClient = []
      this.errorsFinalModules = []
      this.errorsInitialClient = []
      this.errorsInitialModules = []
      this.errorsSameDate = []
      this.errorDateModules = []

      mod.forEach(e => {
        if (moment(e.finalDate, 'YYYY-MM-DD') > moment(this.finalDate.value, "YYYY-MM-DD")) {
          this.errorsFinalModules.push(`${e.module.name}`)
        }
        if (moment(e.initialDate, 'YYYY-MM-DD') < moment(this.initialDate.value, "YYYY-MM-DD")) {
          this.errorsInitialModules.push(`${e.module.name}`)
        }
        if (moment(e.initialDate, 'YYYY-MM-DD') > moment(this.finalDate.value, "YYYY-MM-DD")) {
          this.errorsFinalClient.push(`${e.module.name}`)
        }
        if (moment(e.initialDate, 'YYYY-MM-DD') > moment(this.finalDate.value, "YYYY-MM-DD")) {
          this.errorsInitialClient.push(`${e.module.name}`)
        }
        if (moment(e.initialDate, 'YYYY-MM-DD') > moment(e.finalDate, "YYYY-MM-DD")) {
          this.errorDateModules.push(`${e.module.name}`)
        }
        if (moment(e.finalDate, "YYYY-MM-DD").diff(moment(e.initialDate, 'YYYY-MM-DD')) == 0) {
          this.errorsSameDate.push(`${e.module.name}`);
        }
      })

      let form: any = Object.assign(this.formClientData.value, this.formProjectFocal.value, { reportEmails: this.reportEmailsf.value })

      if (this.errorDateModules.length == 0 && this.errorsSameDate.length == 0 && this.errorsFinalClient.length == 0 && this.errorsInitialClient.length == 0 && this.errorsInitialModules.length == 0 && this.errorsFinalModules.length == 0) {
        this.formClientData.disable();
        this.formProjectFocal.disable();
        this.reportEmailsf.disable();
        this.modules.forms.disable();
        this.loadingSpinner.open();

        this.utils.setStorage('mods', JSON.stringify(mod))

        this.clientFormService.sendClient(form).subscribe(response => {
          this.client = response;
          localStorage.setItem('clientId', this.client.id)
          form = Object.assign(this.client, { modules: mod })
          this.clientEditService.sendForm(this.client.id, form).subscribe((resp) => {
            this.loadingSpinner.close();
            this.utils.removeStorage('mods');
            this.store.dispatch(new ClientFormActions.ResetForm())
            this.router.navigate(['/clients', 'created', this.client.id])
          }, (err) => {
            this.loadingSpinner.close();
            this.config = {
              title: 'Cliente cadastrado',
              message: `<p>Cliente cadastrado, mas não foi possivel ativar os módulos, eles podem ser ativados pela tela de Alteração dos Dados do Cliente!</p>`,
              buttons: [
                {
                  text: 'Finalizar Cadastro',
                  handler: () => {
                    this.alertController.close()
                    this.router.navigate(['/clients', 'created', this.client.id])
                  },
                  type: 'primary'
                },
                {
                  text: 'Alteração de dados do cliente',
                  handler: () => {
                    this.alertController.close()
                    this.router.navigate(['/clients', 'edit', this.client.id])
                  },
                  type: 'outline-dark'
                }
              ]
            }

            this.alertController.configure(this.config)

            this.alertController.open()

            this.firstsend = true

          })

        }, err => {
          this.loadingSpinner.close();
          let msg = ""
          if (err.error.errors[0] == "client: already taken") {
            msg = "Cliente já cadastrado, verificar o campo 'E-mail do contato responsável' na etapa de Ponto focal do projeto.";
          }
          this.config = {
            title: 'Erro no Cadastro',
            message: `<p>Não foi possível cadastrar o cliente. ${msg}</p>`,
            buttons: [
              {
                text: 'fechar',
                handler: () => {
                  this.alertController.close()
                  this.formClientData.enable();
                  this.formProjectFocal.enable();
                  this.reportEmailsf.enable();
                  this.modules.forms.enable();
                },
                type: 'primary'
              }
            ]
          }

          this.alertController.configure(this.config)

          this.alertController.open()

          this.firstsend = true
        })

      }

    } else {
      if (this.dateMax() && this.quantMax()) {
        this.step++;
      }
    }

  }
  gotoForm(step: number) {
    this.step = step;
  }
  backForm() {
    if (this.step == 1) {
      this.step = 1;
    } else {
      if (this.step == 4) {
        let mod = this.modules.getModulesActive();
        this.utils.setStorage('mods', JSON.stringify(mod));
      }
      this.step--;
      if (this.step == 1) {
        if (this.firstsend) {
          this.initialDate.setValue(this.format(this.initialDate.value, "YYYY-MM-DD", "DD/MM/YYYY"))
          this.finalDate.setValue(this.format(this.finalDate.value, "YYYY-MM-DD", "DD/MM/YYYY"))
          this.firstsend = false
        }
      }
    }
  }

  format(e: string, formatInput: string, formatOutput: string) {
    if (moment(e, formatInput).format(formatOutput) != "Invalid date") {
      return moment(e, formatInput).format(formatOutput)
    } else {
      return e
    }
  }

  formatdate(date: string) {
    const arrayDate = date.split('/')
    let newDate = arrayDate[2] + "-" +
      arrayDate[1] + "-" +
      arrayDate[0]
    return newDate
  }

  addEmail(event) {
    event.preventDefault()

    this.reportEmails.push(
      {
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        type: new UntypedFormControl('', [Validators.required]
        )
      }
    );

    this.reportEmailsf.push(new UntypedFormGroup({
      email: this.reportEmails[this.reportEmails.length - 1].email,
      type: this.reportEmails[this.reportEmails.length - 1].type
    }))

    this.deleteEmails = true

    if (this.reportEmails.length < 1) {
      this.deleteEmails = false;
    }
  }
  removeEmail(event, i) {
    event.preventDefault();

    if (this.deleteEmails) {
      if (this.reportEmailsf.controls.length > 1) {
        this.reportEmailsf.removeAt(i)
      } else {
        this.deleteEmails = false;
      }
    } else (
      this.deleteEmails = false
    )

  }

  quantMax() {
    let min: number = parseInt(this.qtMin.value) + 1
    this.qtMax.setValidators([Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(min), Validators.maxLength(6)])
    if (this.qtMax.value < this.qtMin.value) {
      this.qtMax.setErrors({ "notUnique": true });
      this.qtMin.setErrors({ "notUnique": true });
      return false
    } else {
      this.qtMax.setErrors(null);
      this.qtMin.setErrors(null);
      return true
    }
  }

  dateMax() {
    let arrayDate = this.initialDate.value.split('/')
    let inidate = arrayDate[1] + "-" +
      arrayDate[0] + "-" +
      arrayDate[2]

    let arraydate = this.finalDate.value.split('/')
    let finaldate = arraydate[1] + "-" +
      arraydate[0] + "-" +
      arraydate[2]

    if (new Date(inidate) > new Date(finaldate)) {
      this.finalDate.setErrors({ "notUnique": true });
      return false
    } else {
      this.finalDate.setErrors(null);
      return true
    }
  }

  cancel() {
    this.config = {
      title: 'Cancelar cadastro de novo cliente',
      message: `<p>Você tem certeza que deseja cancelar a criação deste cliente? Lembre-se que se cancelar, as alterações feitas não serão salvas</p><p>Deseja realmente cancelar?</p>`,
      buttons: [
        {
          text: 'Sim, cancelar',
          handler: () => {
            this.alertController.close()
            this.store.dispatch(new ClientFormActions.ResetForm())
            this.router.navigate(['/dashboard'])
          },
          type: 'primary'
        },
        {
          text: 'Não continuar cadastrando cliente',
          handler: () => {
            this.alertController.close();
          },
          type: 'outline-dark'
        }
      ]
    }

    this.alertController.configure(this.config)

    this.alertController.open()
  }

  setInvalidForm(date) {

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.loadingSpinner.clearVcr();
  }
}
