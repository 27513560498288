import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { FieldsetHarukiSelectOption, AlertController, LoadingSpinner } from 'mm-ui';
import { ClientService } from '../../../services/client.service';
import { Client } from '../../../interfaces/cliente-interface';
import { ActivatedRoute, Router } from '@angular/router';
import { validateCNPJval } from '../../../services/custom-validators';
import { ReportEmail } from '../client-form/report-email';
import { ClientEditService } from './client-edit.service';
import * as moment from 'moment'
import * as _ from 'lodash'
import { BreadcrumbComponent } from '../../../../../../mm-ui/src/lib/breadcrumb/breadcrumb.component';
import { ListModulesComponent } from '../client-form/list-modules/list-modules.component';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { Utils } from '../../../utils/utils';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import * as ClientEditActions from "../../../actions/client-edit.actions";
import { ReportEmailInterface } from '../../../interfaces/report-email-interface';
import { ModulesInterface } from '../../../interfaces/modules/modules-interface';
import { cnpjMask, numberMask, dateMask, minus } from "../../../utils/masks";
import { ClientEditState } from '../../../clientEdit.state';


@Component({
  selector: 'mm-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})


export class ClientEditComponent implements OnInit, AfterViewInit {
  public isCollapsed = false;


  @ViewChild('bread', { static: true }) bread: BreadcrumbComponent;
  @ViewChild('modules', { static: true }) modules: ListModulesComponent;

  @ViewChild('calendarfocus', { static: true }) calendarfocus: any;

  clientId: string
  client: Client

  cnpj = new UntypedFormControl('', [Validators.required, validateCNPJval]);
  licenseType = new UntypedFormControl('', [Validators.required]);
  companyName = new UntypedFormControl('', [Validators.required]);
  qtMin = new UntypedFormControl('', [Validators.required, Validators.maxLength(6), Validators.min(1)]);
  qtMax = new UntypedFormControl('', [Validators.required, Validators.maxLength(6)]);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  reportEmails: Array<ReportEmail> = [{
    email: new UntypedFormControl(''),
    type: new UntypedFormControl('')
  }];
  autoPartialBlock = new UntypedFormControl(true, [Validators.required]);
  initialDate = new UntypedFormControl('', [Validators.required]);
  finalDate = new UntypedFormControl('', [Validators.required]);
  observations = new UntypedFormControl('');
  name = new UntypedFormControl('', [Validators.required]);
  phone = new UntypedFormControl('', [Validators.required]);
  adminLogin = new UntypedFormControl('');

  deleteEmails: boolean = false

  invalid: Array<string> = []

  config: any

  licenciamentoOptions: Array<FieldsetHarukiSelectOption> = [
    { label: 'Usuários nominados', value: "AVAILABLE_USERS" },
    { label: 'Usuários simultâneos', value: "SIMULTANEOUS_AUTH" }
  ]

  periocidadeOpt: Array<FieldsetHarukiSelectOption> = [
    { label: 'Diário', value: 'DAILY' },
    { label: 'Semanal', value: 'WEEKLY' },
    { label: 'Mensal', value: 'MONTHLY' },
  ]

  form: UntypedFormGroup;

  cnpjMask = cnpjMask

  numberMask = numberMask;
  dateMask = dateMask;
  minus = minus;
  progressRef: NgProgressRef;

  errorsFinalClient: Array<string> = [];
  errorsFinalModules: Array<string> = [];
  errorsInitialClient: Array<string> = [];
  errorsInitialModules: Array<string> = [];
  errorsSameDate: Array<string> = [];
  errorDateModules: Array<string> = [];
  activeClientsModules: any;
  reportEmailsControls

  constructor(private formBuilder: UntypedFormBuilder,
    private vcr: ViewContainerRef,
    private loadingSpinner: LoadingSpinner,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private clientEditService: ClientEditService,
    private store: Store<ClientEditState>,
    private alertController: AlertController,
    private router: Router,
    private progress: NgProgress,
    private utils: Utils) {

    this.loadingSpinner.setViewContainerRef(vcr)
    this.clientId = this.route.snapshot.params.id

    // esse formulario precisa ser criado de acordo com o que o backend retorna em GET clientId,
    // cada novo atributo inserido ou retirado no request deve ser alterado aqui, caso contrario,
    // não será atribuido ao objeto this.client

    this.form = this.formBuilder.group({
      cnpj: this.cnpj,
      licenseType: this.licenseType,
      companyName: this.companyName,
      qtMax: this.qtMax,
      qtMin: this.qtMin,
      email: this.email,
      reportEmails: this.formBuilder.array([]),
      autoPartialBlock: this.autoPartialBlock,
      initialDate: this.initialDate,
      finalDate: this.finalDate,
      observations: this.observations,
      name: this.name,
      phone: this.phone,
      id: new UntypedFormControl(''),
      modules: this.formBuilder.array([]),
      blockEvents: this.formBuilder.array([]),
      autoBlocked: new UntypedFormControl(false),
      licenseKey: new UntypedFormControl(null),
      totalBlock: new UntypedFormControl(null),
      peakCurrentMonth: new UntypedFormControl(null),
      totalUsers: new UntypedFormControl(null),
      totalCurrentSessions: new UntypedFormControl(null),
      activeModules: this.formBuilder.array([
        new UntypedFormControl(null)
      ]),
      licenseValidatedDate: new UntypedFormControl(null),
      enabled: new UntypedFormControl(null),
      reasons: this.formBuilder.array([]),
      adminLogin: this.adminLogin
    })
  }

  ngOnInit() {
    this.progressRef = this.progress.ref()
    this.onChange()
    this.load()
    setTimeout(() => {
      this.form.valueChanges.subscribe(values => {
        this.storeFormData({
          ...values,
          modules: this.modules.getModulesActive()
        })
        this.getErrors()
      })
    }, 1000)
  }

  getStateForm() {
    this.store.select('clientEdit')
      .pipe(first())
      .subscribe((resp) => {
        if (resp && (resp.id === this.clientId)) {
          let data = Object.assign({},
            {
              ...this.form.value,
              companyName: resp.companyName,
              cnpj: resp.cnpj,
              licenseType: resp.licenseType,
              qtMax: resp.qtMax,
              id: resp.id,
              qtMin: resp.qtMin,
              autoPartialBlock: resp.autoPartialBlock,
              initialDate: resp.initialDate,
              finalDate: resp.finalDate,
              observations: resp.observations,
              name: resp.name,
              email: resp.email,
              phone: resp.phone,
              adminLogin: resp?.adminLogin
            })

          this.form.setValue(data)
          if (resp.reportEmails) {
            let formReport = this.form.controls.reportEmails as UntypedFormArray
            let formValue = formReport.value as Array<{}>;
            let respArray = resp.reportEmails as Array<ReportEmailInterface>
            if (!_.isEqual(formValue, respArray)) {
              this.form.controls.reportEmails = this.formBuilder.array([])
              respArray.forEach(report => {
                (this.form.controls.reportEmails as UntypedFormArray).push(this.formBuilder.group(report))
              })
            }
          }
          if (resp.modules) {
            this.activeClientsModules = resp.modules
          } else {
            this.activeClientsModules = data.modules
          }
        }
      })
  }

  onChange() {
    this.qtMax.registerOnChange(() => {
      this.quantMax();
    })
  }

  updateForm(data: Array<ModulesInterface>) {
    // this.form.controls.modules = this.formBuilder.array([])
    // let formModules = this.form.controls.modules as FormArray
    // data.forEach(module => {
    //   formModules.push(this.formBuilder.group(module))
    // })
    this.storeFormData({
      ...this.form.value,
      ...{ modules: this.modules.getModulesActive() }
    })
  }

  ngAfterViewInit() {

  }

  storeFormData(data) {
    this.store.dispatch(new ClientEditActions.SetForm(data))
  }

  load() {
    this.clientService.get(this.clientId).subscribe(response => {

      this.client = response
      this.client.initialDate = moment(this.client.initialDate, "YYYY-MM-DD").format("DD/MM/YYYY")
      this.client.finalDate = moment(this.client.finalDate, "YYYY-MM-DD").format("DD/MM/YYYY")
      try {
        this.form.setValue(this.client)
      } catch (err) {

      } finally { }
      let formReport = this.form.controls.reportEmails as UntypedFormArray;
      this.client.reportEmails.forEach(report => {
        formReport.push(this.formBuilder.group(report))
      })
      if (formReport.controls.length > 0) {
        this.deleteEmails = true;
      }
      let activeModulesForm = this.form.controls.activeModules as UntypedFormArray
      for (let i = 0; i < this.client.activeModules.length; i++) {
        if (i == 0) {
          activeModulesForm.controls[0].setValue(this.client.activeModules[0])
          // activeModulesForm.setValue(this.client.activeModules)
        } else {
          activeModulesForm.push(this.formBuilder.control(this.client.activeModules[i]))
        }
      }
      let modulesForm = this.form.controls.modules as UntypedFormArray
      this.client.modules.forEach(modules => {
        modulesForm.push(this.formBuilder.group(modules))
      })
      if (this.client.modules.length > 0) {
        this.modules.activeModules(this.client.modules)
        this.activeClientsModules = this.client.modules
      }

      this.reportEmailsControls = formReport.controls

      this.getStateForm();
    }, err => {
      console.log(err)
    })
  }

  addEmail(event) {
    event.preventDefault()

    this.reportEmails.push(
      {
        email: new UntypedFormControl('', [Validators.email, Validators.required]),
        type: new UntypedFormControl('', [Validators.required])
      }
    );

    (<UntypedFormArray>this.form.controls['reportEmails']).push(new UntypedFormGroup({
      email: this.reportEmails[this.reportEmails.length - 1].email,
      type: this.reportEmails[this.reportEmails.length - 1].type
    }))

    this.deleteEmails = true

    if (this.reportEmails.length < 1) {
      this.deleteEmails = false;
    }
  }

  objDiff(first: {}, second: {}, result: [string]) {
    Object.keys(first).forEach(key => {
      if (first[key] !== second[key]) {
        result.push(key)
      }
    })
    return result
  }

  removeEmail(event, i) {
    let formReport = this.form.controls.reportEmails as UntypedFormArray
    if (this.deleteEmails) {
      if (formReport.controls.length > 1) {
        formReport.removeAt(i);
      } else {
        this.deleteEmails = false;
      }
    }
  }


  sendForm() {

    this.form.disable();
    this.modules.forms.disable();

    if (moment(this.initialDate.value, "DD/MM/YYYY").format("YYYY-MM-DD") != "Invalid date") {
      this.initialDate.setValue(moment(this.initialDate.value, "DD/MM/YYYY").format("YYYY-MM-DD"))
      this.finalDate.setValue(moment(this.finalDate.value, "DD/MM/YYYY").format("YYYY-MM-DD"))
    }
    if (moment(this.client.initialDate, "DD/MM/YYYY").format("YYYY-MM-DD") != "Invalid date") {
      this.client.initialDate = moment(this.client.initialDate, "DD/MM/YYYY").format("YYYY-MM-DD")
      this.client.finalDate = moment(this.client.finalDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    }

    let mods = this.modules.getModulesActive()

    this.errorsFinalClient = []
    this.errorsFinalModules = []
    this.errorsInitialClient = []
    this.errorsInitialModules = []
    this.errorsSameDate = []
    this.errorDateModules = []

    mods.forEach(e => {
      if (moment(e.finalDate, 'YYYY-MM-DD') > moment(this.finalDate.value, "YYYY-MM-DD")) {
        this.errorsFinalModules.push(`${e.module.name}`)
      }
      if (moment(e.initialDate, 'YYYY-MM-DD') < moment(this.initialDate.value, "YYYY-MM-DD")) {
        this.errorsInitialModules.push(`${e.module.name}`)
      }
      if (moment(e.initialDate, 'YYYY-MM-DD') > moment(this.finalDate.value, "YYYY-MM-DD")) {
        this.errorsFinalClient.push(`${e.module.name}`)
      }
      if (moment(e.initialDate, 'YYYY-MM-DD') > moment(this.finalDate.value, "YYYY-MM-DD")) {
        this.errorsInitialClient.push(`${e.module.name}`)
      }
      if (moment(e.initialDate, 'YYYY-MM-DD') > moment(e.finalDate, "YYYY-MM-DD")) {
        this.errorDateModules.push(`${e.module.name}`)
      }
      if (moment(e.finalDate, "YYYY-MM-DD").diff(moment(e.initialDate, 'YYYY-MM-DD')) == 0) {
        this.errorsSameDate.push(`${e.module.name}`);
      }
    })

    let forms: any = Object.assign(this.form.value, { modules: mods })

    let changedModules = this.modules.getModulesDiff(this.client.modules, mods);
    let diff = this.objDiff(forms, this.client, [null])

    if (changedModules) {
    } else {
      diff.filter(e => { return e != 'modules' })
    }

    this.utils.setStorage('mods', JSON.stringify(mods));

    if (this.errorsFinalClient.length == 0 && this.errorsFinalModules.length == 0 && this.errorsInitialClient.length == 0 && this.errorsInitialModules.length == 0 && this.errorDateModules.length == 0 && this.errorsSameDate.length == 0) {

      if (diff.indexOf('qtMin') == -1 && diff.indexOf('qtMax') == -1 && diff.indexOf('initialDate') == -1 && diff.indexOf('finalDate') == -1 && diff.indexOf('licenseType') == -1 && changedModules == false) {
        this.loadingSpinner.open()
        this.clientEditService.sendForm(this.form.controls.id.value, this.form.value).subscribe((resp) => {
          this.loadingSpinner.close()
          this.utils.removeStorage('mods');
          this.store.dispatch(new ClientEditActions.ResetForm())
          this.router.navigate(['/clients', 'view', this.form.controls.id.value, { altered: true }])
        }, err => {
          this.loadingSpinner.close()
          let errors: Array<string> = []

          if (err.error.errors) {
            err.error.errors.map(e => {
              if (e == "finalDate: must be greater than initialDate") {
                errors.push('Data final deve ser superior à data inicial');
              }
              if (e == "qtMax: must be greater than qtMin") {
                errors.push('Quantidade de licenças máxima deve ser maior do que a minima')
              }
            })
          }

          this.config = {
            title: 'Erro alteração de cliente',
            message: `<p>Não foi possível alterar Cliente</p> ${errors.map(e => { return e + " " })}`,
            buttons: [
              {
                text: 'Fechar',
                handler: () => {
                  this.alertController.close()
                  this.form.enable();
                  this.modules.forms.enable();

                },
                type: 'primary'
              }
            ]
          }

          this.alertController.configure(this.config)

          this.alertController.open()
        })
      } else {
        localStorage.setItem('diffs', JSON.stringify(diff));
        localStorage.setItem('forms', JSON.stringify(this.form.value))
        localStorage.setItem('modules', JSON.stringify(changedModules))

        this.router.navigate(['/clients', 'editmotive', this.clientId])
      }
    } else {
      this.form.enable();
      this.modules.forms.enable();
    }
  }

  getErrors() {
    this.quantMax();
    this.dateMax();
  }

  dateMax() {
    let arrayDate = this.initialDate.value.split('/')
    let inidate = arrayDate[1] + "-" +
      arrayDate[0] + "-" +
      arrayDate[2]

    let arraydate = this.finalDate.value.split('/')
    let finaldate = arraydate[1] + "-" +
      arraydate[0] + "-" +
      arraydate[2]

    if (new Date(inidate) > new Date(finaldate)) {
      this.finalDate.setErrors({ "notUnique": true });
      return false
    } else {
      this.finalDate.setErrors(null);
      return true
    }
  }
  quantMax() {
    let min: number = parseInt(this.qtMin.value) + 1
    this.qtMax.setValidators([Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(min), Validators.maxLength(6)])
  }

  cancel() {
    this.router.navigate(['/clients', 'view', this.clientId])
  }

  modulesInvalid(event) {
    if (event) {
      (<UntypedFormGroup>(<UntypedFormArray>this.form.controls.modules).controls[0]).controls.finalDate.setErrors(null)
    } else {
      (<UntypedFormGroup>(<UntypedFormArray>this.form.controls.modules).controls[0]).controls.finalDate.setErrors({ 'unique': true })

    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.loadingSpinner.clearVcr();
  }
}

