export const environment = {
  production: true,
  recaptcha: {
    key: "6Le2qBEpAAAAAPbFDfubdAHprBnAWcYUMab9BTlQ",
    disabled: false,
    url: "https://www.google.com/recaptcha/enterprise.js"
  },
  endpoint: "statement",
  apiVersion: 1
};
