<mmp5-navbar [show]="false" [menu_items]="[]" [navbar_items]="navbar_items" [menu_toggle]="false" [loggedUser]="loggedUser" [hasPermissionToConfig]="true" [configUrl]="['/clients', 'crud']"></mmp5-navbar>
<div class="mmp-nav-margin">
    <div class="container content">
        <div class="container ps-0">
            <h2>Dashboard</h2>
        </div>
        <div class="row card-row">
            <div class="col-md-12 col-lg-4">
                <mmp5-active-clients-box></mmp5-active-clients-box>
            </div>
            <div class="col-md-12 col-lg-4">
                <section class="mm-box-container">
                    <header class="mm-box-header">
                        <h6 class="m-0">Gráficos</h6>
                    </header>
                    <div class="mm-box-content">
                        <div class="container">
                            <mmp5-graph></mmp5-graph>
                        </div>
                    </div>
                </section>
            </div>
            <div class="col-md-12 col-lg-4">
                <section class="mm-box-container">
                    <header class="mm-box-header">
                        <h6 class="m-0">Usuários Logados</h6>
                    </header>
                    <div class="mm-box-content">
                        <div class="container">
                            <mmp5-graph></mmp5-graph>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div class="row margin-box--data">
            <div class="col-lg-6 col-md-12">
                <section class="mm-box-container">
                    <header class="mm-box-header">
                        <div>
                        <h6 class="m-0">Consumo de CPU</h6>
                        </div>
                    </header>
                    <div class="mm-box-content">
                        <mmp5-graph
                        [height]="278"
                        [width]="478">
                        </mmp5-graph>
                    </div>
                </section>
            </div>
            <div class="col-lg-6 col-md-12">
                <section class="mm-box-container">
                        <header class="mm-box-header">
                            <div >
                                <h6 class="m-0">Espaço em Disco</h6>
                            </div>
                        </header>
                        <div class="mm-box-content">
                            <mmp5-graph
                            [height]="278"
                            [width]="478">
                        </mmp5-graph>
                    </div>
                </section>
            </div>
        </div>
        <hr>
        <div class="row margin-box--serve">
            <div class="col-lg-6 col-md-12">
                <section class="mm-box-container">
                    <header class="mm-box-header">
                        <div>
                            <h6 class="m-0">Consumo de Memória RAM</h6>
                        </div>
                    </header>
                    <div class="mm-box-content">
                        <mmp5-graph
                        [height]="278"
                        [width]="478">
                        </mmp5-graph>
                    </div>
                </section>
            </div>
            <div class="col-lg-6 col-md-12">
                <section class="mm-box-container">
                    <header class="mm-box-header">
                        <div>
                        <h6 class="m-0">Consumo de Memória do Banco de Dados</h6>
                        </div>
                    </header>
                <div class="mm-box-content">
                    <mmp5-graph
                    [height]="278"
                    [width]="478">
                    </mmp5-graph>
                </div>
                </section>
            </div>
        </div>
    </div>
</div>
