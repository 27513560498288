import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { User } from '../models/user';

@Injectable()
export class SigninGuard  {

  constructor(public user: User, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.user.isAuthenticated()) {
      this.router.navigate(['clients']);
      return false;
    } else {
      return true;
    }

  }

}
