import { NgModule } from "@angular/core";
import { DashboardComponent } from "./dashboard.component";
import { DashboardService } from "./dashboard.service";
// import { GraphComponentModule } from "mm-ui";
import { GraphComponentModule, NavbarComponentModule } from "mm-ui";
import { RouterModule, Routes } from "@angular/router";
import { ActiveClientsBoxModule } from "../../reusable/active-clients-box/active-clients-box.module";
import { LoginGuard } from "../../guards/login-guard";

export const routes: Routes = [
    {path: 'dashboard', data:{breadcrumb:'breadcrumb.dashboard.dashboard.dashboard'}, canActivate:[LoginGuard], component: DashboardComponent}
]

@NgModule({
    declarations: [DashboardComponent],
    imports:[
        GraphComponentModule,
        NavbarComponentModule,
        ActiveClientsBoxModule,
        RouterModule.forRoot(routes, {})
    ],
    providers: [
        DashboardService
    ], exports: [
        RouterModule
    ]
})

export class DashboardComponentModule{

}
