<div #vcr class="container">
  <mmp5-breadcrumb></mmp5-breadcrumb>
    <section class="mm-box-container">
    <div>
      <header class="mm-box-header">Configurações e cadastro</header>
    </div>
    <div class="mm-box-content">
      <div class="border-tabs">

        <!-- TABs -->

        <mat-tab-group [selectedIndex]="selectedIndex || 0" (selectedTabChange)="tabChanged($event)">

          <!-- tab 001 --------------------------------------------------------------------------------------->

          <mat-tab label="Motivos de alteração cadastral">
            <div class="register-flex mt-1 d-block" *ngIf="messageStatus!=''">
              <div class="alert-adjust">
                <mm-alert [type]="messageStatus" [text]="messageText">
                </mm-alert>
              </div>
            </div>
            <div class="register-flex mt-1">
              <div>
                <div class="row">
                  <div class="col-10">
                    <p class="p-style">Abaixo estão listados os motivos de alteração cadastral para os clientes. Caso não exista um motivo listado que você precisa, é possível cadastrar nessa área, bem como editar  ou desativar os motivos existentes.</p>
                  </div>
                  <div class="col-2">
                    <button type="button" (click)="alertReason()" class="btn btn-primary btn-block"><strong>Cadastrar motivo</strong></button>
                  </div>
                </div>
              </div>

            </div>
            <div class="list-reasons">
              <mm-list-reasons
              #reasons
              scroll-size="unset"
              (status)="getStatus($event)">
            </mm-list-reasons>
          </div>
        </mat-tab>

        <!-- tab 002 --------->
        <mat-tab label="Usuários e perfis">
          <div class="container">
            <div class="mt-1 ms-1 me-1">
              <div class="ms-2 me-2" *ngIf="messageStatusUser!=''">
                <div class="alert-adjust">
                  <mm-alert [type]="messageStatusUser" [text]="messageTextUser">
                  </mm-alert>
                </div>
              </div>
              <div class = "row">
                <div class="col-10">
                  <div class="register-flex">
                    <div *ngIf="!users">
                      <p [class]="{'mt-2 pt-1': error == false || alter == '' || created == false}">Você ainda não cadastrou nenhum usuário. Para adicionar um novo usuário, clique no botão "Cadastrar usuário" e inclua outras pessoas para acessar a ferramenta.</p>
                    </div>
                    <div>
                      <p class="mt-2 pt-1">Você ainda não cadastrou nenhum usuário. Para adicionar um novo usuário, clique no botão "Cadastrar usuário" e inclua outras pessoas para acessar a ferramenta.</p>
                    </div>
                  </div>

                </div>
                <div class="col-2 ps-0">
                  <div class="mt-2 pt-1">
                    <button type="button" (click)="alertUser()" class="btn btn-primary"><strong>Cadastrar usuário</strong></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="list-reasons mt-1" *ngIf="users">
              <mm-list-users
              scroll-size="unset"
              (userChangeEnable)="userEnableDisable($event)"
              (hasUsers) = "setUsers($event)">
              </mm-list-users>
          </div>

        </div>
      </mat-tab>


      <!-- tab 003 ------->
      <mat-tab label="Módulos">
        <div class="container">
          <div class="container-x-border">
            <mm-crud-list-modules scroll-size="unset"></mm-crud-list-modules>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Relatórios">
        <div class="container">
          <mm-list-reports scroll-size="unset"></mm-list-reports>
        </div>
      </mat-tab>

    </mat-tab-group>



      </div>
    </div>
    </section>
</div>

