import { Injectable } from "@angular/core";
import { AppConfig } from "../../../app.config";
import { HttpClient } from "@angular/common/http";
import { ReasonInterface } from "../client-editmotive/reason-interface";

@Injectable()

export class ClientCrudService{
    
    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`
    
    constructor (private http: HttpClient){
        
    }
    
    createUser(data: any){
        return this.http.post(`${this.endpoint}/users`, data);
    }
    
    
    createModule(data: any){ 
        return this.http.post(`${this.endpoint}/modules`, data);        
    }
    
    editModule(data){
        return  this.http.put(`${this.endpoint}/modules/${data.id}`, data);
    }
    
    createReason(data: any){
        return this.http.post<ReasonInterface>(`${this.endpoint}/reasons`, data);
        
    }
}