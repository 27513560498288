import { Component, OnInit, ViewContainerRef } from '@angular/core';

import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FieldsetHarukiSelectOption } from 'mm-ui';
import { Router } from '@angular/router';
import { Globals } from '../../utils/globals';

@Component({
  selector: 'mm-clients',
  templateUrl: './clients-component.html',
  styleUrls: ['./clients-component.scss']
})
export class ClientsComponent implements OnInit {

  navbar_items: Array<string> = []
  constructor(
    private router: Router,
    private globals: Globals
  ) {
    
  }

  ngOnInit() {
    
    this.navbar_items = this.globals.globals.navbar_items;
  }


}
