<div class="container">
    <div class="container-border">
      <div class="title-flex">
        <div>
          <h6>Configurações e cadastro</h6>
        </div>
        <div>
          <a href="#" alt="botão fechar">
            <!-- <i class="fa fa-times close-btn"></i> -->
            <mmp5-mm-icons class="close-btn" [icon]="['fa-solid','fa-xmark']"></mmp5-mm-icons>
          </a>
        </div>

      </div>
      <hr>
      <p>Você tem certeza que deseja desbloquear este cliente?</p>
      <p>Lembre-se o desbloqueio completo ativa todas as sessões de usuários logados e libera a utilização de todos os serviços da ferramenta.</p>
      <p>Para que este cliente seja desbloqueado, você deve listar um motivo para realizar essa ação.</p>
      <p><strong>Desbloqueio completo do cliente [nome do cliente]</strong></p>

      <div class="box">
        <mmp5-fieldset-haruki
        [formControl]="input"
        [key]="'client_company_name'"
        [label]="'input text'"
        [helperText]="'text helper'"
        ></mmp5-fieldset-haruki>
      </div>

      <div class="container button-flex">
        <div class="justify-content-start">
          <button type="button" class="btn  bg-danger">Sim, desbloquear cliente</button>
        </div>

        <div class="justify-content-start">
          <button type="button" class="btn bg-danger">Não, voltar para a visualização dos dados</button>
        </div>
      </div>
    </div>

  </div>









