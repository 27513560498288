import { Pipe, PipeTransform } from "@angular/core";
import { Client } from "../../interfaces/cliente-interface";

@Pipe({ name: 'filterByDescription'})
export class FilterByDescription implements PipeTransform {

    transform(clientList:Client[], descriptionQuery: string) {
        descriptionQuery = descriptionQuery
            .trim()
            .toLowerCase();

        if(descriptionQuery) {
            return clientList.filter(clients => clients.companyName.toLowerCase().includes(descriptionQuery));
        } else {
            return clientList;
        }
    }
}