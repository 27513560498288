import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ViewComponent } from './view.component';
import { TranslateModule } from '@ngx-translate/core';
import { AlertControllerModule, AlertModule } from 'mm-ui';

@NgModule({
  declarations: [
    ViewComponent
  ],
  imports: [
    RouterModule,
    TranslateModule,
    AlertControllerModule,
    AlertModule
  ],
  providers: [],
  exports: [
    ViewComponent
  ]
})
export class ViewModule { }
