<div class="mt-1">
  <div>
    <div class="row register-flex d-flex align-items-center">
      <div class="col-10">
        <p>Abaixo estão listados os relatórios Jasper.</p>
      </div>
      <div class="col-2">
        <div class="d-flex justify-content-end">
          <button type="button" (click)="alertReport()" class="btn btn-primary btn-block"><strong>Cadastrar relatório</strong></button>
        </div>
      </div>
    </div>
  </div>

</div>


<mm-table
  *ngIf="reportsList.length > 0"
  [values]="reportsList"
  [columnNames]="columnName"
  [tableSize]="scrollSize"
  [thClass]="['size',null,null]"
  [tdClass]="['asd',null,null]"
  (tdClick)="tableEditReports($event)">
</mm-table>
