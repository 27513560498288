import { AppConfig } from "../../../app.config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()

export class ClientEditMotiveService {

    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`

    constructor(private http: HttpClient){

    }

    getReasons() {
        return this.http.get<any>(`${this.endpoint}/reasons?active=true`)
    }
}