import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
    providedIn: 'root'
})

export class Utils{

  constructor(private messageService: MessageService) {}

    hasStorage(name){
        return !!this.getStorage(name);
    }
    setStorage(name, obj){
        localStorage.setItem(name, obj);
    }

    getStorage(name){
        return localStorage.getItem(name);
    }

    removeStorage(name){
        localStorage.removeItem(name);
    }

    toastMessage(severity, summary, detail){
      this.messageService.add({severity, summary, detail});
    }

}
