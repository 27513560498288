import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";

import { ErrorValidationModule,
TooltipModule,
MmTableModule,
AlertControllerModule,
AliceKeysConfigModule,
NavbarComponentModule,
GraphComponentModule,
WizardStepsModule,
FieldsetHarukiModule,
BreadcrumbModule,
SearchSuggestModule,
AlertModule,
MMIconsModule } from "mm-ui"

import { ClientsComponent } from "./clients-component";
import { ClientFormComponent } from "./client-form/client-form.component";
import { ClientCreatedComponent } from "./client-created/client-created.component";
import { PageInternalCustomer_UnlockClientComponent } from "./page-internalcustomer_unlockClient/page-internalcustomer_unlockClient";
import { PageCrudDisableComponent } from "./page-cruddisable/page-cruddisable";
import { ClientListComponent } from "./client-list/client-list.component";
import { ClientViewComponent } from "./client-view/client-view.component";
import { PageInternalCustomer_BlockClientComponent } from "./page-internalcustomer_blockClient/page-internalcustomer_blockClient";
import { ErrorPageComponent } from "../error-page/error-page.component";
import { ListModulesComponent } from "./client-form/list-modules/list-modules.component";
import { ListModuleService } from "./client-form/list-modules/list-module.service";
import { LicencePipe } from "../../pipes/licence.pipe";
import { ReportTimePipe } from "../../pipes/report-time.pipe";
import { ClientFormService } from "./client-form/client-form.service";
import { NgbDateBRParserFormatter } from "../../utils/ngb-date-parser-formatter";
import { ClientCreatedGuard } from "../../guards/client-created.guard";
import { ClientEditComponent } from "./client-edit/client-edit.component";
import { LoginGuard } from "../../guards/login-guard";
import { ClientEditService } from "./client-edit/client-edit.service";
import { ClientListResolver } from "./client-list/client-list.resolver";
import { ClientEditMotiveComponent } from "./client-editmotive/client-editmotive.component";
import { ClientEditMotiveService } from "./client-editmotive/client-editmotive.service";
import { ClientCrudComponent } from "./client-crud/client-crud.component";
import { ListUsersComponent } from "./client-crud/list-users/list-users.component";
import { ListReasonsComponent } from "./client-crud/list-reasons/list-reasons.component";
import { ListUsersService } from "./client-crud/list-users/list-users.service";
import { ClientCrudService } from "./client-crud/client-crud.service";
import { CrudListModulesComponent } from "./client-crud/list-modules/list-modules.component";
import { AlertControllerComponent } from "../../../../../mm-ui/src/lib/alert-controller/alert-controller.component";
import { ClientActivationComponent } from "./client-activation/client-activation.component";
import { ClientActivationService } from "./client-activation/client-activation.service";
import { MMIconsComponent } from "../../../../../mm-ui/src/lib/mm-icons/mm-icons.component";
import { ListReasonsService } from "./client-crud/list-reasons/list-reasons.service";
import { AutoSearchModule } from "../../reusable/autosearch/autosearch.module";
import { FilterByDescription } from "../../reusable/autosearch/autosearch.pipe";
import { ClientActiveComponent } from "./client-active/client-active.component";
import { ClientActiveService } from "./client-active/client-active.service";
import { ListRepoertsComponent } from "./client-crud/list-reports/list-reports.component";
import { ListReportsService } from "./client-crud/list-reports/list-reports.service";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarModule } from 'primeng/sidebar';


const clientRoutes: Routes = [
  {
    path: 'clients', data: { breadcrumb: 'breadcrumb.clients.list' }, canActivate: [LoginGuard], component: ClientsComponent, children: [
      { path: '', data: { breadcrumb: 'breadcrumb.clients.list' }, redirectTo: 'list', pathMatch: 'full', resolve: { clients: ClientListResolver } },
      { path: 'list', data: { breadcrumb: 'breadcrumb.clients.list' }, component: ClientListComponent, resolve: { clients: ClientListResolver } },
      { path: 'form', data: { breadcrumb: 'breadcrumb.clients.form' }, component: ClientFormComponent },
      { path: 'created/:id', data: { breadcrumb: 'breadcrumb.clients.created' }, component: ClientCreatedComponent, canActivate: [ClientCreatedGuard] },
      { path: 'view/:id', data: { breadcrumb: 'breadcrumb.clients.view' }, component: ClientViewComponent },
      { path: 'block', data: { breadcrumb: 'breadcrumb.clients.block' }, component: PageInternalCustomer_BlockClientComponent },
      { path: 'unlock', data: { breadcrumb: 'breadcrumb.clients.unlock' }, component: PageInternalCustomer_UnlockClientComponent },
      { path: 'crud', data: { breadcrumb: 'breadcrumb.clients.crud' }, component: ClientCrudComponent },
      { path: 'cruddisable', data: { breadcrumb: 'breadcrumb.clients.cruddisable' }, component: PageCrudDisableComponent },
      { path: 'edit/:id', data: { breadcrumb: 'breadcrumb.clients.edit' }, component: ClientEditComponent },
      { path: 'editmotive/:id', data: { breadcrumb: 'breadcrumb.clients.edit' }, component: ClientEditMotiveComponent },
      { path: '**', component: ErrorPageComponent }
    ]
  },
]

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorValidationModule,
    NavbarComponentModule,
    GraphComponentModule,
    WizardStepsModule,
    FieldsetHarukiModule,
    BreadcrumbModule,
    AlertControllerModule,
    MatExpansionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTabsModule,
    MmTableModule,
    MatTooltipModule,
    TooltipModule,
    NgbAccordionModule,
    SearchSuggestModule,
    AlertModule,
    AutoSearchModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule.forChild(clientRoutes),
    MMIconsModule,
    TranslateModule,
    AliceKeysConfigModule,
    SidebarModule
  ],
  declarations: [
    ListUsersComponent,
    ListReasonsComponent,
    ListRepoertsComponent,
    ClientActivationComponent,
    ClientFormComponent,
    ClientCreatedComponent,
    ClientsComponent,
    ClientListComponent,
    ClientViewComponent,
    ClientEditComponent,
    ListModulesComponent,
    ClientActiveComponent,
    PageInternalCustomer_BlockClientComponent,
    PageInternalCustomer_UnlockClientComponent,
    ClientCrudComponent,
    PageCrudDisableComponent,
    LicencePipe,
    ReportTimePipe,
    ClientEditMotiveComponent,
    CrudListModulesComponent,
    FilterByDescription
  ],
  exports: [
    RouterModule,
    MatTooltipModule
  ],
  providers: [
    ClientActivationService,
    ListModuleService,
    ListUsersService,
    ListReasonsService,
    ListReportsService,
    ClientFormService,
    ClientEditService,
    ClientCreatedGuard,
    ClientEditMotiveService,
    ClientCrudService,
    ClientActiveService,
    { provide: NgbDateBRParserFormatter, useClass: NgbDateBRParserFormatter }
  ]
})

export class ClientsModule { }
