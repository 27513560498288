import { Component, OnInit } from "@angular/core";
import { ClientActivationService } from "./client-activation.service";
import { ActivatedRouteSnapshot, Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: 'mm-client-activation',
    templateUrl: 'client-activation.component.html',
    styleUrls: ['client-activation.component.scss']
})

export class ClientActivationComponent implements OnInit {
    id: string

    constructor(private clientActivationService: ClientActivationService,
                private route: ActivatedRoute,
                private router: Router){

    }

    ngOnInit(){
        this.id = this.route.snapshot.params.id
        this.clientActivationService.activeClient(this.id).subscribe(resp => {
            
            this.router.navigate(['email-login', resp.resetPasswordToken, {activated: true}])
        }, err => {
            console.log(err)
            this.router.navigate(['email-login', this.id, {error: true}])
        })
    }
}