import { Component, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from 'mm-ui';
import { Router, Data, NavigationEnd, ActivatedRoute } from '@angular/router';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers:[MessageService]
})
export class AppComponent {


  constructor(private alertCtrl: AlertController,
              private vcr: ViewContainerRef,
              private translate: TranslateService,
              private messageService: MessageService,
              private router: Router,
              private route: ActivatedRoute) {

    // translate.addLangs(['en', 'pt-BR']);
    // translate.setDefaultLang('pt-BR');
    // translate.use('pt-BR');

    alertCtrl.setViewContainerRef(vcr);

    const body = vcr.element.nativeElement.parentNode;
    let currentCustomBgClass = null;

    // this.router
    // .events
    // .filter(event => event instanceof NavigationEnd)
    // .map(() => {
    //   let child = this.route.firstChild;
    //   if (child.snapshot.data.logged) {
    //     body.classList.add('logged');
    //   } else {
    //     body.classList.remove('logged');
    //   }
    //   while (child) {
    //     if (child.firstChild) {
    //       child = child.firstChild;
    //     } else if (child.snapshot.data && child.snapshot.data['background']) {
    //       return child.snapshot.data['background'];
    //     } else {
    //       return null;
    //     }
    //   }
    //   return null;
    // }).subscribe( (customData: any) => {
    //   body.classList.remove(currentCustomBgClass);
    //   if (customData) {
    //     body.classList.add(customData);
    //     currentCustomBgClass = customData;
    //   }
    // });
  }
}
