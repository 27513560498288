import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user';
import { AlertController } from 'mm-ui';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  email: string;

  subscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private user: User,
    private alertController: AlertController,
    private translate: TranslateService,
    private _ngZone: NgZone
  ) { }

  ngOnInit() {
    this.email = this.user.getEmail() || '';

    this._ngZone.runOutsideAngular(() => {
      this.checkExpiration();
    });
  }

  checkExpiration() {

    const expireDate = this.user.getTokenExpirationDate();

    if (!expireDate) {
      return;
    }
    const diff = (moment().diff(expireDate) * - 1);

    // this.subscription = Observable.timer(diff).subscribe( () => {

    //   this.alertController.configure({
    //     title: this.translate.instant('signout.warning.title'),
    //     message: this.translate.instant('signout.warning.message'),
    //     buttons: [
    //       {
    //         type: 'primary',
    //         text: this.translate.instant('signout.warning.ok'),
    //         handler: () => {
    //           this.signOut();
    //         }
    //       }
    //     ]
    //   });
    //   this._ngZone.run(() => {
    //     this.alertController.open();
    //   });
    // });
  }

  signOut() {
    this.authService.signout()
    .subscribe( status => {
      this.router.navigate(['users', 'signin']);
      this.subscription.unsubscribe();
    }, err => {
      console.error('Logout error: ' + err);

      //TODO: some message
    });
  }

}
