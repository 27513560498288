import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FieldsetHarukiSelectOption } from 'mm-ui';

@Component({
  selector: 'mmp5-page-cruddisable',
  templateUrl: './page-cruddisable.html',
  styleUrls: ['./page-cruddisable.scss'],
  
})
export class PageCrudDisableComponent implements OnInit {
  
  fieldOptions = new UntypedFormControl();
  form: UntypedFormGroup;

  optionsAAA: Array<FieldsetHarukiSelectOption> = [{ label: 'name 0', value: 0  },
  { label: 'name 1', value: 1  },
  { label: 'name 2', value: 2 },
  { label: 'name 3', value: 3 }];

  optionsBBB: Array<FieldsetHarukiSelectOption> = [{ label: 'name 0', value: 0  },
  { label: 'name 1', value: 1  },
  { label: 'name 2', value: 2 },
  { label: 'name 3', value: 3 }];

  optionsCCC: Array<FieldsetHarukiSelectOption> = [{ label: 'name 0', value: 0  },
  { label: 'name 1', value: 1  },
  { label: 'name 2', value: 2 },
  { label: 'name 3', value: 3 }];

  options: Array<FieldsetHarukiSelectOption> = [{ label: 'name 0', value: 0  },
  { label: 'name 1', value: 1  },
  { label: 'name 2', value: 2 },
  { label: 'name 3', value: 3 }];
  
  constructor(private formBuilder: UntypedFormBuilder) {
    
  }
  
  ngOnInit() {
  }
  
  
}
