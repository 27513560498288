import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { LoginService } from "../login.service";
import { AppConfig } from "../../../app.config";
import { Subscription } from "rxjs";

@Component({
    selector: 'mm-email-reset',
    templateUrl: 'email-reset.component.html',
    styleUrls: ['email-reset.component.scss', '../login.component.scss']
})

export class EmailResetCompoent implements OnInit, OnDestroy {
    email= new UntypedFormControl('',[Validators.required, Validators.email])
    sended: boolean = false;
    error: boolean = false
    hasRecaptcha = !AppConfig.data['recaptcha']['disabled'];
    key: string;

    form:UntypedFormGroup

    emailResetExecutionSubscription: Subscription;
    recentToken:string = ''

    constructor(
        private loginservice: LoginService,
         private fb: UntypedFormBuilder
        ){

    }

    ngOnInit() {

        this.form = this.fb.group({
            email: this.email
        })

    }

    sendReset(act) {

        this.recentToken= ''

        if(this.hasRecaptcha){

            if (this.emailResetExecutionSubscription) {
                console.log('sigle')
                this.emailResetExecutionSubscription.unsubscribe();
            }

            this.emailResetExecutionSubscription =this.loginservice.googleRecaptchaV3(act)
                .subscribe((token) => {

                    this.recentToken = token;
                    this.resetAct()
                }
            );

        }else{

            console.log('recaptcha disabled')
            this.resetAct()
        }

    }

    resetAct(){

        this.loginservice.passReset({
            email: this.email.value
          }).subscribe(response=> {

              let f = <HTMLElement>document.getElementById('form')
              f.classList.add('disabled');

              let alert = <HTMLElement>document.getElementById('sended')
              alert.classList.remove('disabled');
              this.sended = true;
          }, err=> {
              this.error = true
          })
    }

    ngOnDestroy(): void {

        if(this.emailResetExecutionSubscription)
        this.emailResetExecutionSubscription.unsubscribe()
    }

}
