import { Injectable } from '@angular/core'
import { Action } from '@ngrx/store'
import { Client } from "../models/client";

export const SET_FORM = '[CLIENTEDIT] Set';
export const RESET_FORM = '[CLIENTEDIT] Reset';

export class SetForm implements Action {
    readonly type = SET_FORM

    constructor(public payload: Client){}
}

export class ResetForm implements Action{
    readonly type = RESET_FORM

    constructor(){}
}

export type Actions = SetForm | ResetForm