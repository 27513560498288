import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActiveClientsBox } from "./active-clientes-box.component";

@NgModule({
    declarations: [ActiveClientsBox],
    exports:[ActiveClientsBox],
    imports:[CommonModule]
})
export class ActiveClientsBoxModule {

}