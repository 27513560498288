import { NgModule } from "@angular/core";
import { AutoSearchComponent } from "./autorsearch.component";
import { CommonModule } from "@angular/common";
import { MMIconsModule } from "mm-ui";

@NgModule({
    declarations: [AutoSearchComponent],
    exports: [AutoSearchComponent],
    imports: [
      CommonModule,
      MMIconsModule
    ]
})
export class AutoSearchModule {

}
