import { AbstractControl } from "@angular/forms";
import * as moment from 'moment'

export function monthYearValidator(control: AbstractControl): {[key: string]: boolean} | null {
    let value: string = String(control.value)
    if(moment(value, 'MM/YYYY').isValid()){
        return null
    }else {
        return {'monthYearInvalid': true}
    }
}
