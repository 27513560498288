import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store'
import { Client } from "../models/client"
import * as ClientEditFormActions from './../actions/client-edit.actions'

const initialState: Client = {
    cnpj: '',
    companyName: '',
    contact_email: '',
    contact_emails: '',
    contact_name: '',
    reportEmails:[],
    email: '',
    id: null,
    licenseType: null,
    modules: [],
    name: '',
    initialDate: '',
    finalDate: '',
    autoPartialBlock: true,
    observations: '',
    phone: '',
    qtMax: 0,
    qtMin: 0,
    send_email_type: null,
    url: null
}
export function reducerEdit(state: Client, action: ClientEditFormActions.Actions){
    switch(action.type){
        case ClientEditFormActions.SET_FORM:
            return Object.assign({}, state, action.payload);
        case ClientEditFormActions.RESET_FORM:
            return initialState;
        default:
            return state;
    }
}