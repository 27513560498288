import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, Input } from "@angular/core";
import { ReasonsList } from "../../../../interfaces/reasonslist";
import { ListReasonsService } from "./list-reasons.service";
import { FormControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from "@angular/forms";
import { AlertController } from "mm-ui";
import { ReasonInterface } from "../../client-editmotive/reason-interface";

@Component({
    selector: 'mm-list-reasons',
    styleUrls: ['list-reasons.component.scss'],
    templateUrl: 'list-reasons.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ListReasonsComponent implements OnInit{

    reasons: Array<ReasonsList> = []
    form: UntypedFormArray
    selectedReason: ReasonsList
    eReason: ReasonsList
    reasonList: Array<any> = []
    columnName: Array<string> = [
        'Motivo de alteração cadastral',
        'Ativo?',
        'Editar'
    ]

    @Input('scroll-size') scrollSize:string;

    @Output('status') status = new EventEmitter;
    oldForm: any;

    constructor(private listReasonsService: ListReasonsService,
                private formBuilder: UntypedFormBuilder,
                private alertController: AlertController){

    }

    ngOnInit() {
        this.getReasons();
        this.form = this.formBuilder.array([])
    }

    getReasons() {
        this.form = this.formBuilder.array([])
        this.reasons = [];
        this.listReasonsService.getReasons(true).subscribe(resp => {
            resp.content.forEach(reason => {
                this.reasons.push(reason)
                this.form.push(
                    this.formBuilder.group({
                        id: this.formBuilder.control(reason.id),
                        description: this.formBuilder.control(reason.description),
                        active: this.formBuilder.control(reason.active)
                    })
                )
            })
            this.listReasonsService.getReasons(false).subscribe(resp => {
                resp.content.forEach(reason => {
                    this.reasons.push(reason)
                    this.form.push(
                        this.formBuilder.group({
                            id: this.formBuilder.control(reason.id),
                            description: this.formBuilder.control(reason.description),
                            active: this.formBuilder.control(reason.active)
                        })
                    )
                })
                this.oldForm = this.form.value
                this.getTableData();
                this.formChange();
            })
        })
    }

    formChange(){
        this.form.controls.forEach((control, i) => {
            control.valueChanges.subscribe(resp => {
                this.disableReason({target:{checked: resp.active}}, i)
            })
        })
    }

    getTableData(){
        this.reasonList = []
        this.reasons.map((reason, i) => {
            let tableRow = {
                [ReasonName.description] : reason.description,
                [ReasonName.enable]: [{
                    formControl: (<UntypedFormGroup>this.form.controls[i]).controls.active,
                    type: 'switch',
                    key: 'switch_reason'+i
                }],
                beforeIcons: [null,null,['icon icon-toolbar-editar']],
                iconEmitter: true
            }
            this.reasonList = [
                ...this.reasonList,
                tableRow
            ]
        })
    }

    tableeditReason(e){
        let index = this.reasons.map(reason => reason.description)
        this.editReason(index.indexOf(e[ReasonName.description]))
    }

    disableReason(event, i){
        let id = (<UntypedFormGroup>this.form.controls[i]).controls.id.value
        this.eReason = this.form.value.find( u => u.id == id);
        this.eReason.active = event.target.checked

        this.listReasonsService.editReasons(this.eReason).subscribe(resp => {
            this.status.emit('reasonEdited'+resp.active)
        },err => {
            this.status.emit('reasonEditedFail')
        } )
    }

    sendEdit(val) {
        if(this.reasons.map(e => {return e.description}).indexOf(val[0].value) == -1){
            this.selectedReason.description = val[0].value
            this.listReasonsService.editReasons(this.selectedReason).subscribe(resp => {
                this.status.emit('altered');
                this.getReasons()
            }, err => {
                this.status.emit('error');
            })
        }else {
            this.status.emit('already');
        }
    }

    editReason(i){
        let id = (<UntypedFormGroup>this.form.controls[i]).controls.id.value
        this.selectedReason = this.form.value.find( u => u.id == id);
        let config = {
            title: 'Editar motivo cadastrado',
            message: 'Edite um motivo cadastrado para o cliente',
            input: [
                {
                    label: 'Motivo de alteração cadastral',
                    value: this.selectedReason.description
                },
            ],
            buttons: [
                {
                    text: 'Salvar edição',
                    type: 'primary',
                    handler: (val) => {
                        this.sendEdit(val),
                        this.alertController.close()
                    },
                },
                {
                    text: 'Cancelar',
                    handler: () => {
                        this.alertController.close();
                    },
                    type: 'outline-dark'
                }
            ]
        }

        this.alertController.configure(config);
        this.alertController.open();
    }

}

export enum ReasonName {
    description = 'Motivo de alteração cadastral',
    enable = 'Ativo?',
    edit = 'Editar'
}
