import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';

import { ClientCrudService } from "../client-crud.service";
import { AlertController } from "mm-ui";
import { ModuleInterface } from "../../../../interfaces/modules/module-interface";
import { ListModuleService } from "../../client-form/list-modules/list-module.service";

@Component({
    selector: 'mm-crud-list-modules',
    templateUrl: './list-modules.component.html',
    styleUrls: ['./list-modules.component.scss']
})

export class CrudListModulesComponent implements OnInit {

    modules: Array<ModuleInterface> = [];

    @Input() private modulesContent;
    @Input() type:string;
    @Input() text:string;
    @Input('scroll-size') scrollSize:string;

    altered:boolean = false;

    switchButton = new UntypedFormControl(true);
    modulesData: Array<any> = [];
    columnNames = [
        ListModulesNames.name,
        ListModulesNames.urlPath,
        ListModulesNames.available,
        ListModulesNames.edit,
    ]

    constructor(
        private crudService: ClientCrudService,
        private alertController: AlertController,
        private listModuleService: ListModuleService
    ){

    }

    ngOnInit(): void {
        this.getModules();
    }

    getModules() {
        this.listModuleService.getModules(true)
        .subscribe(modules => {
            this.modules = [];
            this.modules.push(modules);
            this.modulesContent = modules.content;
            this.getTableData();
        })
    }

    getTableData(){
        this.modulesData = []
        this.modulesContent.map((mod, i) => {
            let tableRow = {
                [ListModulesNames.name] : mod.name,
                [ListModulesNames.urlPath]: mod.urlPath,
                [ListModulesNames.available]: [{
                    type: 'switch',
                    key: 'switch_list_modules'+i,
                    formControl: new UntypedFormControl(mod.available)
                }],
                beforeIcons: [null,null,null,['icon','icon-toolbar-editar']],
                iconEmitter: true,
                id: mod.id
            }
            this.modulesData = [
                ...this.modulesData,
                tableRow
            ]
        })
    }

    createModule(event){

        event.preventDefault();

        this.alertController.configure({

            title: `Cadastro de novo módulo`,
            message: `<p>Cadastre um novo módulo para ativar para os clientes</p>`,
            input: [
                {
                  label: 'Nome do Módulo',
                  value: '',
                },
                {
                    label: 'Url do Módulo',
                    value: '',
                }
            ],
            buttons: [
              {
                text: 'Cadastrar Módulo',
                type: 'primary',
                handler: (val) => {

                    const createModule = {
                        'name': val[0].value,
                        'urlPath': val[1].value,
                        'available':true
                    }

                   this.crudService.createModule(createModule)
                    .subscribe((newModule:ModuleInterface) => {
                        this.modulesContent.push(newModule);
                        this.altered = true;
                        this.type = 'success';
                        this.text = 'Módulo adicionado com sucesso. Agora ele será exibido na conta do usuário para ser ativado.';
                        this.getModules();

                    },
                    err => {
                        this.altered = true;
                        this.type = 'danger';
                        this.text = 'Não foi possível criar o módulo.'
                    }
                    );
                }
              },
              {
                text: 'Cancelar',
                type: 'outline-dark',
                handler: () => {
                }
              }
            ]

          });

          this.alertController.open();
    }

    editModuleTable(e){

        this.editModule(e.id, e[ListModulesNames.name], e[ListModulesNames.urlPath], e[ListModulesNames.available].value);
    }

    editModule(moduleId, moduleName, moduleUrl, available) {

        this.alertController.configure({

            title: `Cadastro de novo módulo`,
            message: `<p>Cadastre um novo módulo para ativar para os clientes</p>`,
            input: [
                {
                  label: 'Nome do Módulo',
                  value: moduleName,
                },
                {
                    label: 'Url do Módulo',
                    value: moduleUrl,
                }
            ],
            buttons: [
              {
                text: 'Editar Módulo',
                type: 'primary',
                handler: (val) => {

                    const editModule:ModuleInterface = {
                        'id': moduleId,
                        'name': val[0].value,
                        'urlPath': val[1].value,
                        'available':available
                    }

                   this.crudService.editModule(editModule)
                    .subscribe((editmodule:ModuleInterface) => {
                        let filtered = this.modulesContent.filter((element) => {

                            if(element.id != editModule.id)
                                return element
                        });
                        this.modulesContent = filtered;
                        this.modulesContent.push(editmodule);
                        this.getTableData();
                        this.altered = true;
                        this.type = 'success';
                        this.text = 'Módulo editado com sucesso.'
                    },
                        err => {
                            this.altered = true;
                            this.type = 'danger';
                            this.text = 'Não foi possível editar o módulo.'
                        }
                    );
                }
              },
              {
                text: 'Cancelar',
                type: 'outline-dark',
                handler: () => {
                  console.log('closed');
                }
              }
            ]
          });

          this.alertController.open();
    }
}

export enum ListModulesNames {
    name = 'Módulo',
    urlPath = 'URL',
    available = 'Ativo?',
    edit = 'Editar'
}
