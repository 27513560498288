import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../../../app.config";

@Injectable()
export class ClientActiveService {

    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}`


    constructor(private http: HttpClient){

    }

    sendActive(data){
        return this.http.post(`${this.endpoint}/${this.apiVersion}/clients/activation/`, data)
    }

}