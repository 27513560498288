<div class="container">
  <mmp5-breadcrumb>
  </mmp5-breadcrumb>

  <section class="mm-box-container">
    <header class="mm-box-header">
      <h6 class="mb-0">Alteração de dados do cliente</h6>
    </header>
    <div class="mm-box-content">
      <mat-accordion>
        <!-- aba 001 --- Dados da Empresa ----------------------------------------------------------->
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <strong> Dados da Empresa </strong>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- input nome do cliente -->
          <div class= "row justify-content-center">
            <div class ="col-12 col-lg-8">
              <div class="content">

                <div class="row mt-1">
                  <div class="col-12">
                    <mmp5-fieldset-haruki
                    [formControl]="companyName"
                    [key]="'companyName_edit'"
                    [label]="'Nome do Cliente'"
                    [showError]="false"
                    ></mmp5-fieldset-haruki>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-12">
                    <!-- input cnpj -->
                    <mmp5-fieldset-haruki
                    [formControl]="cnpj"
                    [key]="'cnpj_edit'"
                    [label]="'CNPJ'"
                    [mask]="cnpjMask"
                    [showError]="false"
                    ></mmp5-fieldset-haruki>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-12">
                    <mmp5-fieldset-haruki
                    [formControl]="licenseType"
                    [key]="'licenciamento_edit'"
                    [label]="'Tipo de Licenciamento'"
                    [selectOptions]="licenciamentoOptions"
                    [showError]="false"
                    [value]="client?.licenseType"
                    ></mmp5-fieldset-haruki>
                  </div>
                </div>

                <!-- Quantidade de licenças contratadas -->
                <div>
                  <p class="text-style">
                    <strong> Quantidade de licenças contratadas </strong>
                  </p>
                </div>

                <div class="row">
                  <div class="col-12 col-lg-4">
                    <p>Mínimo</p>
                    <mmp5-fieldset-haruki
                    [formControl]="qtMin"
                    [key]="'qtMin'"
                    [type]="'number'"
                    [mask]="minus"
                    ></mmp5-fieldset-haruki>
                  </div>

                  <div class="col-12 col-lg-4">
                    <p>Máximo</p>
                    <mmp5-fieldset-haruki
                    [formControl]="qtMax"
                    [key]="'number'"
                    [type]="'number'"
                    [mask]="minus"
                    ></mmp5-fieldset-haruki>
                  </div>

                  <div class="col-12 col-lg-4 mt-3 partialBlock">
                    <mmp5-fieldset-haruki
                    [formControl]="autoPartialBlock"
                    [key]="'autoPartialBlock'"
                    [label]="'Efetuar bloqueio automático'"
                    [checked]="true"
                    [type]="'checkbox'">
                  </mmp5-fieldset-haruki>
                </div>

              </div>
               <!-- Observação -->
            <div >
                <div class="text-timecontract">
                    <p class="text-style">
                      <strong>Observação</strong>
                    </p>
                  </div>
                <div class="row">
                  <div class="col-12">
                    <mmp5-fieldset-haruki
                      [formControl]="observations"
                      [key]="'observation'"
                      [label]="'Observação'"
                      [showError]="false">
                  </mmp5-fieldset-haruki>
                  </div>
                </div>
              </div>
              <!-- Vigência do contratos -->
              <div>
                <div class="text-timecontract">
                  <p class="text-style">
                    <strong> Vigência do contrato</strong>
                  </p>
                </div>
                <p>A data de início da vigência do contrato deverá ser menor que a data de término</p>

                <div class="row date">
                  <div class="col-12 col-lg-6">
                      <mmp5-fieldset-haruki
                      [formControl]="initialDate"
                      [type]="'date'"
                      [key]="'date'"
                      [label]="'Data Inicial'"
                      [mask]="dateMask">
                    </mmp5-fieldset-haruki>
                    <div id="input-calendar-resize" ></div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <mmp5-fieldset-haruki
                    [formControl]="finalDate"
                    [type]="'date'"
                    [key]="'date'"
                    [label]="'Data Final'"
                    [mask]="dateMask"
                    (blur)="dateMax()"
                    [showError]="false"
                    ></mmp5-fieldset-haruki>
                    <div id="input-calendar-resize" ></div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

        </mat-expansion-panel>

    <!-- aba 002 --- Ponto focal do projeto ----------------------------------------------------------->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>Ponto focal do projeto</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="content">
        <!-- input nome do cliente -->
        <div class="row justify-content-center">
          <div class="col-12 col-lg-8">
            <div class="row">
              <div class="col-12">
                <mmp5-fieldset-haruki
                [formControl]="name"
                [key]="'name'"
                [label]="'Nome do contato responsável'">
              </mmp5-fieldset-haruki>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <mmp5-fieldset-haruki
              [formControl]="email"
              [key]="'email'"
              [label]="'E-mail do contato responsável'"
              ></mmp5-fieldset-haruki>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <mmp5-fieldset-haruki
              [formControl]="adminLogin"
              [key]="'adminLogin'"
              [label]="'Login do contato responsável'"
              ></mmp5-fieldset-haruki>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <mmp5-fieldset-haruki
              [formControl]="phone"
              [key]="'phone'"
              [label]="'Telefone do contato responsável'"
              [type]="'phone'"
              ></mmp5-fieldset-haruki>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <!-- aba 003 --- Contatos  para relatórios ------------------------------------------------------>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong> Contatos para Relatórios</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="content">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">

          <ng-template ngFor let-group [ngForOf]="reportEmailsControls" let-i="index">
            <div class="row">
              <div class="col-11">
                <hr [ngClass]="{'hr-report': true, 'mt-2': true,  'mb-2': true}" *ngIf="i!=0">
                <mmp5-fieldset-haruki
                [formControl]="group.get('email')"
                [key]="'e-mail'"
                [label]="'E-mail do contato responsável'"
                ></mmp5-fieldset-haruki>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div class="row">
                  <div class="col-11">
                    <mmp5-fieldset-haruki
                    [formControl]="group.get('type')"
                    [key]="'options'"
                    [label]="'Periodicidade do envio de relatórios'"
                    [selectOptions]="periocidadeOpt"
                    ></mmp5-fieldset-haruki>
                    <div *ngIf="i==(reportEmails.length-1)" id="selectOptionsResize">
                    </div>
                  </div>
                  <div class="col-1 col-lg-auto ps-0" *ngIf="deleteEmails">
                      <button class="btn-as-link" (click)="removeEmail($event, i)">
                          <!-- <i class="fa fa-trash"></i> -->
                          <mmp5-mm-icons [icon]="['fa-solid','fa-trash-can']"></mmp5-mm-icons>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <div class="row">
            <div class="col-11">
              <div class="row justify-content-end">
                <div class="col-4">
                  <a class="form-text text-right mb-1" href="" (click)="addEmail($event)">Adicionar novo e-mail</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <!-- aba 004 --- Módulos contratados ---------------------------------------->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong> Módulos contratados</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="content">
      <!-- table start ---------------------------------------------------------->
      <mm-list-modules
      [type]="'enable'"
      [clientid]="client?.id"
      (valid)="modulesInvalid($event)"
      [activeClientsModules]="activeClientsModules"
      (update)="updateForm($event)"
      scroll-size="unset"
      #modules>
    </mm-list-modules>
    <div id="input-calendar-resize"></div>
    <!-- table end ----------------------------------------------------------->
  </div>
</mat-expansion-panel>

      </mat-accordion>
<div class="mt-1">
  <div class="row" *ngIf="errorsFinalModules.length>0">
      <div class="col-12">
          <mm-alert [type]="'danger'" [text]="'Data final dos módulos: '+errorsFinalModules+', superior á data final do contrato.'"></mm-alert>
      </div>
  </div>
  <div class="row" *ngIf="errorsInitialModules.length>0">
      <div class="col-12">
          <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorsInitialModules+', inferior á data inícial do contrato.'"></mm-alert>
      </div>
  </div>
  <div class="row" *ngIf="errorsFinalClient.length>0">
      <div class="col-12">
          <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorsFinalClient+', superior á data final do contrato.'"></mm-alert>
      </div>
  </div>
  <div class="row" *ngIf="errorsInitialClient.length>0">
      <div class="col-12">
          <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorsInitialClient+', inferior á data inícial do contrato.'"></mm-alert>
      </div>
  </div>
  <div class="row" *ngIf="errorsSameDate.length>0">
      <div class="col-12">
          <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorsSameDate+', é a mesma da data final.'"></mm-alert>
      </div>
  </div>
  <div class="row" *ngIf="errorDateModules.length>0">
      <div class="col-12">
          <mm-alert [type]="'danger'" [text]="'Data inícial dos módulos: '+errorDateModules+', é superior á data final.'"></mm-alert>
      </div>
  </div>
</div>

</div>
<div class="mm-box-footer">
  <div class="d-flex justify-content-end">
      <a class="btn btn-outline-dark me-2" (click)="cancel()">Cancelar</a>
      <button class="btn btn-primary" [disabled]="form.invalid" (click)="sendForm()">Salvar dados do cliente</button>
  </div>
</div>

</section>

</div>
