import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ActiveClientsInterface } from "../../interfaces/active-clients-interface";
import { DashboardService } from "../../components/dashboard/dashboard.service";

@Component({
    selector: 'mmp5-active-clients-box',
    templateUrl: './active-clients-box.component.html',
    styleUrls: ['./active-clients-box.component.scss']
})
export class ActiveClientsBox {

    activeClients: ActiveClientsInterface

    constructor(
        private router: Router,
        private dashboardService: DashboardService
    ){
        dashboardService.getClients()
            .subscribe(resp => this.activeClients = resp)
    }

    cadastrarCli() {
        this.router.navigate(['clients', 'form'])
    }

    verlistaClientes() {
        this.router.navigate(['clients','list'])
    }

}