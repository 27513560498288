    <div class="alert-adjust" *ngIf="altered">
        <mm-alert [type]="type" [text]="text"></mm-alert>
    </div>

    <div *ngFor="let modules of modules" >


        <div class="register-flex">
            <div>
                <p class="p-style" *ngIf="modules.totalElements == 0; else hasModules">Você ainda não cadastrou nenhum módulo. Para cadastrar, clique no botão "Cadastrar módulo" e inclua módulos para ativar na conta dos clientes.</p>
            </div>
            <ng-template #hasModules>
                <p class="p-style"> Abaixo estão listados os módulos e suas respectivas urls</p>
            </ng-template>

            <div class="justify-content-start">
                <button type="button" (click)="createModule($event)" class="btn bg-primary"><strong>Cadastrar módulo</strong></button>
            </div>
        </div>

        <div *ngIf="modules.totalElements > 0">
            <mm-table
                *ngIf="modules.totalElements > 0"
                [values]="modulesData"
                [tableSize]="scrollSize"
                [columnNames]="columnNames"
                [thClass]="['size','size']"
                (tdClick)="editModuleTable($event)">
            </mm-table>
    </div>
</div>


