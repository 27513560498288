import { Component, Input, OnInit, HostBinding, forwardRef, ViewChildren, QueryList, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ListModuleService } from "./list-module.service";
import { ModulesInterface } from "../../../../interfaces/modules/modules-interface";
import { ModulesOptInterface } from "./modules-opt-interface";
import * as moment from "moment"
import { ModuleInterface } from "../../../../interfaces/modules/module-interface";
import { dateMask } from "../../../../utils/masks";
import { Utils } from "../../../../utils/utils";
import { Enable } from "projects/f-monit/src/app/interfaces/moduleEnable";

@Component({
    selector: 'mm-list-modules',
    templateUrl:'list-modules.component.html',
    styleUrls:['list-modules.component.scss']
})

export class ListModulesComponent implements OnInit {

    forms: UntypedFormArray

    @Input() type: string
    @Input() clientid: string
    @Input() modules: Array<ModuleInterface> = []
    @Input('activeClientsModules') activeClientsModules: Array<ModulesInterface> = []
    @Input('finalDate') finalDate: string
    @Input('disable') disable: boolean;
    @Input('scroll-size') scrollSize: string;

    @Output('valid') valid = new EventEmitter();
    @Output('update') update = new EventEmitter();

    oldModules: Array<string> = []
    newModules: Array<string>= []
    mods: Array<ModulesInterface> = []
    tableDataView: Array<any> = []
    tableDataEnable: Array<any> = []

    dateMask = dateMask

    disabled: boolean = false;
    date: string

    objs= {
        'false': 'Não',
        'true': 'Sim'
    }

    columnNames = [
        'Módulos',
        'Ativo',
        'Início da vigência do módulo',
        'Final da vigência do módulo'
    ]

    columnNamesEnable = [
        'Módulo',
        'Ativar',
        'Vigência do módulo'
    ]

    modulesIds: Array<string> = []

    @HostBinding('attr.data-expand') dataExpand:any = false;

    constructor(private listModuleService: ListModuleService,
                private formBuilder: UntypedFormBuilder,
                private utils: Utils) {

        }

        ngOnInit(): void {
        this.date = moment(Date.now()).format('DD/MM/YYYY')
        this.mods = JSON.parse(this.utils.getStorage('mods'));
        this.utils.removeStorage('mods');
        this.listModuleService.getModules(true).subscribe(response => {
            this.addModules(response.content)
            if(this.activeClientsModules && this.activeClientsModules.length > 0){
                this.activeModules(this.activeClientsModules)
            }else if(this.mods != null){
                this.activeModules(this.mods)
            }
            this.getTableDataView();
            this.getTableDataEnable();
            this.formChange();
        });

        if(this.disable) {
            this.disabled = true;
        }

    }

    getTableDataView() {
        let formValue = this.forms.value
        this.modules.forEach((module, i) => {
            let tableRow = {
                'Módulos': module.name,
                'Ativo': Enable[formValue[i].active],
                'Início da vigência do módulo': formValue[i].initialDate,
                'Final da vigência do módulo': formValue[i].finalDate
            }

            this.tableDataView = [
                ...this.tableDataView,
                tableRow
            ]
        })
    }

    formChange() {
        this.forms.valueChanges.subscribe((resp: ModulesInterface[]) => {
            let data = resp.filter(mod => mod.active)
            // this.activeClientsModules = data
            this.update.emit(data)
        })
    }

    getTableDataEnable() {
        this.modules.forEach((module, i) => {
            let tableRow = {
                'Módulo': module.name,
                'Ativar': [{
                    formControl: (<UntypedFormGroup>this.forms.controls[i]).controls.active,
                    type: 'switch',
                    label: '',
                    key: 'switchEnableModule'+i
                }],
                'Vigência do módulo': [
                    {
                        formControl: (<UntypedFormGroup>this.forms.controls[i]).controls.initialDate,
                        type: 'date',
                        label: 'Início*',
                        key: 'initialDate'+i,
                        mask: this.dateMask
                    },
                    {
                        formControl: (<UntypedFormGroup>this.forms.controls[i]).controls.finalDate,
                        type: 'date',
                        label: 'Término*',
                        key: 'finalDate'+i,
                        mask: this.dateMask
                    }
                ]
            }

            this.tableDataEnable = [
                ...this.tableDataEnable,
                tableRow
            ]
        })
    }

    activeModules(mods: Array<ModulesInterface>){
        let i = 0;
        this.oldModules = []
        this.modules.forEach(m => {
            mods.forEach(md=>{
                if(m.id == md.module.id){
                    (<UntypedFormGroup>this.forms.controls[i]).controls.active.setValue(true);
                    (<UntypedFormGroup>this.forms.controls[i]).controls.finalDate.setValue(moment(md.finalDate,"YYYY-MM-DD").format("DD/MM/YYYY"));
                    (<UntypedFormGroup>this.forms.controls[i]).controls.initialDate.setValue(moment(md.initialDate,"YYYY-MM-DD").format("DD/MM/YYYY"));
                    (<UntypedFormGroup>this.forms.controls[i]).controls.id.setValue(md.id);
                    this.oldModules.push(md.module.name)
                }
            })
            i++
        })
    }

    getModulesActive(): Array<any>{
        return this.forms.value.filter((e)=>{
            if(e.active){
                return true
            } else {
                return false
            }
        }).map(e=>{
            let item = {...e}
            this.newModules = []
            item.finalDate = this.format(e.finalDate, "DD/MM/YYYY", "YYYY-MM-DD")
            item.initialDate = this.format(e.initialDate, "DD/MM/YYYY", "YYYY-MM-DD")
            this.newModules.push(item.module.name)
            return item
        })
    }

    format(e: string, formatInput: string, formatOutput: string){
        if(moment(e,formatInput).format(formatOutput)!="Invalid date"){
            return moment(e,formatInput).format(formatOutput)
        } else {
            return e
        }
    }

    addModules(mods:any){
        this.forms = this.formBuilder.array([])
        mods.forEach(m => {
            this.modulesIds.push(m.id);
            this.forms.push(
                this.formBuilder.group({
                    module: this.formBuilder.group({
                        name: new UntypedFormControl(m.name),
                        id: new UntypedFormControl(m.id),
                        urlPath: new UntypedFormControl(m.urlPath),
                        available: new UntypedFormControl(m.available)
                    }),
                    active: new UntypedFormControl(false),
                    initialDate: new UntypedFormControl(''),
                    finalDate: new UntypedFormControl(''),
                    id: new UntypedFormControl('')
                })
            )
            this.modules.push(m)
        })
    }

    getModulesDiff(old?: Array<ModulesInterface>,newModules?: Array<ModulesInterface>){
        if(old && newModules){
            let newModul = newModules.filter(e => { return old.indexOf(e) == -1 })
            let disableModul = old.filter(e => { return newModules.indexOf(e) == -1 })

            if(newModul.length != 0 || disableModul.length != 0){
                return {
                    new: newModul,
                    disable: disableModul
                }
            }else {
                return false
            }
        }else {
            let newModul = this.newModules.filter(e => { return this.oldModules.indexOf(e) == -1 })
            let disableModul = this.oldModules.filter(e => { return this.newModules.indexOf(e) == -1 })

            if(newModul.length != 0 || disableModul.length != 0){
                return {
                    new: newModul,
                    disable: disableModul
                }
            }else {
                return false
            }
        }
    }

    expand(status: any, i:number){
        if(status.status == true){
            this.dataExpand = ((this.modules.length - 1 ) - i)
        } else {
            this.dataExpand = false
        }
    }

    valDate(event){
        let id = (<HTMLElement>event.target).id.replace(new RegExp('[A-Za-z_]','g'),'')
        this.forms.controls[id].controls.finalDate.setValidators([Validators.minLength(this.forms.controls[id].controls.initialDate.value)])

        let initial = moment(this.forms.controls[id].controls.initialDate.value, "DD/MM/YYYY");
        let final = moment(this.forms.controls[id].controls.finalDate.value, "DD/MM/YYYY");

        if (final < initial){
            this.forms.controls[id].controls.finalDate.setErrors({'unique': true})
            this.valid.emit(false);
        } else {
            this.forms.controls[id].controls.finalDate.setErrors(null)
            this.valid.emit(true);
        }
    }
}
