import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './components/error-page/error-page.component';

import { LoginComponent } from './components/login/login.component';
import { ClientActivationComponent } from './components/clients/client-activation/client-activation.component';
import { ClientActiveComponent } from './components/clients/client-active/client-active.component';

const appRoutes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'user/activation/:id', component: ClientActivationComponent },
  { path: 'client-activation/:token', component: ClientActiveComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', component: ErrorPageComponent },

];

export const routing = RouterModule.forRoot(appRoutes, {});
