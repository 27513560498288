<div class="container">
    <mmp5-breadcrumb ></mmp5-breadcrumb>
</div>
<div class="container">

    <div class="row justify-content-center">
        <div class="col-9">
            <section class="mm-box-container">
                <div class="mm-box-content">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-8 mb-2 text-center">                            
                            <h6 class="m-0"><i class="fa fa-check-circle m-1 text-success"></i>Cliente {{client?.companyName}} adicionado com sucesso.</h6>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10">
                            <p>
                            O cliente {{client?.companyName}} receberá um e-mail na lista {{client?.email}} assim que o contrato entrar em vigência para confirmar a efetivação do cadastro.
                            </p>
                            <p>
                            Ele será informado de que deverá entrar em contato com a equipe da MMCafé para receber a chave de acesso e acessar o MMP5.
                            </p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-6">
                            <div class="alert alert-dark mt-2 mb-2">
                                <p class="text-center mb-1">
                                    A chave de acesso para este cliente é:
                                </p>
                                <h4 class="text-center">{{client?.licenseKey}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10">
                                <strong>Observação:</strong> essa chave ficará visível no cadastro do cliente, bem como o status de ativação da ferramenta.
                        </div>
                    </div>
                    <div class="row justify-content-center mt-2">
                        <div class="col-12 col-lg-4">
                            <a class="btn btn-primary btn-block" routerLink="/clients/list">Ir para a lista de clientes</a>                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

</div>