

<div class="form-style">
    <div class="form-style--adjust">
        <input type="search" (keyup)="debounce.next($event.target.value)" placeholder=" Buscar pelo nome do cliente" [value]="value" class="form-control" />
    </div>
    <div class="fa-icon">
        <!-- <i class="fa fa-search"></i> -->
        <mmp5-mm-icons [icon]="['fa-solid','fa-magnifying-glass']"></mmp5-mm-icons>
    </div>
</div>
