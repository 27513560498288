import { Injectable } from "@angular/core";
import { Client } from "../../interfaces/cliente-interface";
import { Observable } from "rxjs";
import { ActiveClientsInterface } from "../../interfaces/active-clients-interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../app.config";

const reportHeader = {
  useReportToken: 'true'
}

@Injectable({
    providedIn: 'root'
})


export class DashboardService{
    clients: ActiveClientsInterface;

    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`;
    reportEndpoint = `${AppConfig.data['services']['report']}/${this.apiVersion}`
    cmsEndpoint = `${AppConfig.data['services']['cms']}/${this.apiVersion}`;
    userEndpoint =    `${AppConfig.data['services']['user']}/${this.apiVersion}`;
    flowsurveyEndpoint = `${AppConfig.data['services']['flowsurvey']}/${this.apiVersion}`;
    endpointDownloadReport = `${AppConfig.data['services']['gateway']}/download/report/${this.apiVersion}`;
    endpointDownloadFlowsurvey = `${AppConfig.data['services']['gateway']}/download/flowsurvey/${this.apiVersion}`

    constructor(private http: HttpClient) {

    }



    getClients(): Observable<ActiveClientsInterface> {
        return this.http.get<ActiveClientsInterface>(`${this.endpoint}/clients`);
    };


    _do_get(path: string){

    };

    _do_post(path: string) {

    };

    getJasperReports() {
      return this.http.get(`${this.reportEndpoint}/report/all`,{headers: reportHeader})
    };

    getTemplateForms(token) {
      return this.http.get(`${this.cmsEndpoint}/features/forms/template`, {headers: reportHeader})
    };

    getPageForms(token) {
      return this.http.get(`${this.cmsEndpoint}/features/forms/page`,{ headers: reportHeader})
    };

    downloadJasperReport(id, data) {
      return this.http.put(`${this.endpointDownloadReport}/jasper/${id}`, data, { responseType: 'blob', observe: 'response', headers: reportHeader })
    };

    getFlowsurveyNodeCreationReport(initialDate: string, finalDate: string) {
      return this.http.get(`${this.endpointDownloadFlowsurvey}/flowsurvey/report/creationDate`, { observe: 'response', responseType: 'blob', params: { initialDate, finalDate }, headers: reportHeader });
    };

    getReport(reportType, reportFormData, sessionPeakId?: string) {
      if (reportType == 'pageAccess') {
        return this.http.get(`${this.endpointDownloadReport}/${reportType}`, { params: reportFormData, responseType: 'blob', observe: 'response', headers: reportHeader })
      } else if (reportType == 'sessions') {
        return this.http.get(`${this.endpointDownloadReport}/usersSessions`, { params: reportFormData, responseType: 'blob', observe: 'response', headers: reportHeader })
      } else if (reportType == 'sessionsPeak') {
        return this.http.get(`${this.endpointDownloadReport}/sessionsPeak/users/${sessionPeakId}`, { responseType: 'blob', observe: 'response', headers: reportHeader })
      } else if (reportType == 'activeUsersPeak') {
        return this.http.get(`${this.endpointDownloadReport}/activeUsersPeak/users/${reportFormData.month}/${reportFormData.year}`, { responseType: 'blob', observe: 'response', headers: reportHeader })
      } else if (reportType == 'forms') {
        return this.http.get(`${this.endpointDownloadReport}/formData`, { params: reportFormData, responseType: 'blob', observe: 'response', headers: reportHeader })
      } else if (reportType == 'alteredPages')
        return this.http.get(`${this.endpointDownloadReport}/pageAction`, { params: reportFormData, responseType: 'blob', observe: 'response', headers: reportHeader })
      else if (reportType == 'ratingFeedback')
        return this.http.get(`${this.endpointDownloadReport}/feedback`, { params: reportFormData, responseType: 'blob', observe: 'response', headers: reportHeader })
      else if (reportType == 'periodPermission')
        return this.http.get(`${this.endpointDownloadReport}/aclAction`, { params: reportFormData, responseType: 'blob', observe: 'response', headers: reportHeader })
    };

    getSessionPickId(data) {
      return this.http.get(`${this.endpointDownloadReport}/sessionsPeak/${data.month}/${data.year}`, {headers: reportHeader});
    };

    searchUsers(
      token,
      data: {
      query?: string,
      showOff?: boolean,
      showNotChargeable?: boolean,
      showNotViewUser?: boolean,
      size?: string | number,
      page?: string | number,
      sort?: string
      }) {
      /**
      * Returns the list of users. the Loggeduser needs to have UP ou UP_PLUS permission. for view with loggedUser without UP use searchUserView.
      *
      * @param data - The user search query
      * @returns The list of Users
      *
      * @beta
      */
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + 'aaaa').set('Content-Type', 'application/json');
      console.log(headers)
      return this.http.get<any>(`${this.userEndpoint}/users/search`,{ params: data as any, headers: reportHeader});
    }
}
