<div class="purple">
    <div class="margins">
                <section class="mm-box-container card-login">
                    <div class="mm-box-header">
                        <div class="row justify-content-center mb-2">
                            <div class="col-auto">
                                <img class="logo" class="img-responsive" src="../../assets/mmp5.png">
                            </div>
                        </div>
                        <div class="row justify-content-center mb-2">
                            <div class="col-12 text-center">
                                <p class="mb-0">Ative o cliente ter acesso ao sistema. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mm-box-content">
                        <div class='row' *ngIf="activation">
                            <div class="col-12">
                                <div class="alert alert-success">
                                    Cliente ativado com sucesso! Será enviado um email com link de acesso ao sistema.
                                </div>
                            </div>
                        </div>
                        <div class='row' *ngIf="errorActivation">
                            <div class="col-12">
                                <div class="alert alert-danger">
                                    Não foi possível ativar o cliente, contate o suporte.
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-12 mb-2" *ngIf="licenseKey">
                                    <p>Digite a chave de acesso informada pelo atendimento para ativar o cliente. </p>
                                    <mmp5-fieldset-haruki [formControl]="licenseKey" [label]="'Chave de acesso'"
                                        [showError]="false">
                                    </mmp5-fieldset-haruki>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-12">
                                    <button (click)="activeClient()" [disabled]="licenseKey.invalid || disable" class="btn btn-secondary btn-block">Ativar
                                        cliente</button>
                                </div>
                            </div>
                        </div>
                        <div class="disabled" id="sended">
                            <div class="row">
                                <div class="col-12 mb-1">
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="sended">
                            <div class="col-12">
                                <a routerLink="/login" class="btn btn-secondary btn-block">Voltar</a>
                            </div>
                        </div>
                    </div>
                </section>
    </div>
</div>