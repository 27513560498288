import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../../app.config";
import { GetReasons } from "../../../../interfaces/getReasons";
import { Injectable } from "@angular/core";
import { ReasonsList } from "../../../../interfaces/reasonslist";

@Injectable()

export class ListReasonsService {


    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`

    constructor(private http: HttpClient){

    }

    getReasons(active: boolean){
        return this.http.get<GetReasons>(`${this.endpoint}/reasons?active=${active}`)
    }

    editReasons(reason: ReasonsList){
        return this.http.put<ReasonsList>(`${this.endpoint}/reasons/${reason.id}`, reason)
    }
}