<div class="container">
  <div class="container-border">
    <div class="title-flex">
      <div>
        <h6> Bloquear cliente [nome do cliente] </h6>
      </div>
      <div>
        <a href="#" alt="botão fechar">
          <!-- <i class="fa fa-times close-btn"></i> -->
          <mmp5-mm-icons class="close-btn" [icon]="['fa-solid','fa-xmark']"></mmp5-mm-icons>
        </a>
      </div>

    </div>
    <hr>
    <p>Você tem certeza que deseja bloquear este cliente?</p>
    <p>Lembre-se o bloqueio completo interrompe todas as sessões de usuários logados e bloqueia a utilização de todos os serviços da ferramenta.</p>
    <p>Para que este cliente seja bloqueado, você deve listar um motivo para realizar essa ação.</p>
    <p><strong>Bloqueio completo do cliente [nome do cliente]</strong></p>

    <div class="box">
      <mmp5-fieldset-haruki
      [formControl]="input"
      [key]="'client_company_name'"
      [label]="'input text'"
      [helperText]="'text helper'"
      ></mmp5-fieldset-haruki>
    </div>

    <div class="container button-flex">
      <div class="justify-content-start">
        <button type="button" class="btn  bg-danger">Sim, bloquear cliente</button>
      </div>

      <div class="justify-content-start">
        <button type="button" class="btn bg-danger">Não, voltar para a visualização dos dados</button>
      </div>
    </div>
  </div>

</div>






