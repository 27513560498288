import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  constructor(public route: ActivatedRoute,
    public router: Router) { }

  ngOnInit() {
    if (parseInt(this.route.snapshot.params.type, 10) === 401) {
      this.router.navigate(['users', 'signin']);
    }
  }

}
