import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ClientService } from '../../../services/client.service';
import { Client } from '../../../interfaces/cliente-interface';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../../../utils/globals';
import { LicenceTypes } from '../../../models/licenceTypes';

@Component({
  selector: 'mm-list-clients',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {

  private clientList: Array<Client> = [];
  columnNames: Array<{}> = [
    'Nome do Cliente',
    'Tipo de Licenciamento',
    'Licenças / Pico do mês corrente',
    'Usuários'
  ]


  @Input() private allClientList: Array<Client> = [];
  @Input('class') class: string = 'class';

  tableData: Array<any> = [];
  param: string;
  sort: boolean = true;
  page: number;
  size: number;
  order: string;
  query: string;
  last: boolean;

  alertClass: string;
  alertText: string;
  altered: boolean;

  searchForm = new UntypedFormGroup({
    searchInput: new UntypedFormControl(''),
  });
  constructor(
    private formBuilder: UntypedFormBuilder,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private globals: Globals) {

    this.page = this.globals._globals.pagination.page;
    this.size = this.globals._globals.pagination.size;
    this.order = this.globals._globals.pagination.sort;
    this.query = this.globals._globals.pagination.query;
  }

  filter(e: string) {
    if (e) {
      this.tableData = this.allClientList
        .filter(clients => clients.companyName.toLocaleLowerCase().startsWith(e))
        .map((client: Client) => ({
          'Nome do Cliente': client.companyName,
          'Tipo de Licenciamento': LicenceTypes[client.licenseType] || '',
          'Licenças / Pico do mês corrente': client.peakCurrentMonth,
          'Usuários': client.totalUsers,
          'linkTo': `/clients/view/${client.id}`
        }))
    } else {
      this.getTableData()
    }
  }

  ngOnInit() {

    const activatedRoute = this.route.snapshot.data.clients;

    this.clientList = activatedRoute.content;
    this.getTableData();
    this.last = activatedRoute.last;

    this.clientService.allClients()
      .subscribe(allClients => this.allClientList.push(...allClients.content));

  }

  searchAutocomplete() {
    return new Observable(observer => {

    });
  }

  getTableData() {
    if (this.clientList) {
      this.tableData = this.clientList.map((client: Client) => ({
        'Nome do Cliente': client.companyName,
        'Tipo de Licenciamento': LicenceTypes[client.licenseType] || '',
        'Licenças / Pico do mês corrente': client.peakCurrentMonth,
        'Usuários': client.totalUsers,
        'linkTo': `/clients/view/${client.id}`
      }))
    }
  }

  load() {
    this.clientService.clientPaginated(++this.page, this.order, this.query, this.size)
      .subscribe(clients => {
        this.clientList.push(...clients.content)
        this.getTableData();
        this.last = clients.last;
      })
  }

  toggleOrder(param, event) {

    this.sort = !this.sort;
    this.param = param;
    let sort = this.sort ? 'asc' : 'desc'
    let order = param + "," + sort;

    this.clientService.clientPaginated(this.page, order, this.query, this.size)
      .subscribe(clients => {
        this.clientList = clients.content;
        this.last = clients.last;
      })
  }

  reindexAllClients() {
    this.clientService.reindexAllClientUsers().subscribe({
      next: () => {
        this.altered = true;
        this.alertClass = 'success';
        this.alertText = 'Usuários indexados com sucesso';
      },
      error: () => {
        this.alertClass = 'danger';
        this.alertText = 'Não foi possível indexar os usuários';
      }
    })
  }

  reindexAllClientsMedias() {
    this.clientService.reindexAllClientMedias().subscribe({
      next: () => {
        this.altered = true;
        this.alertClass = 'success';
        this.alertText = 'Mídias indexadas com sucesso';
      },
      error: () => {
        this.alertClass = 'danger';
        this.alertText = 'Não foi possível indexar as mídias';
      }
    })
  }

}
