import { Pipe, PipeTransform } from "@angular/core";
import { ReportTimes } from "../models/reportTimes";

@Pipe({
    name: 'reportTime'
  })
  export class ReportTimePipe implements PipeTransform {
  
    transform(value: any, args?: any): any {
      return ReportTimes[value] === undefined ? null : ReportTimes[value];
    }
  
  }