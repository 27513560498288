import { Component, Input, ViewChild } from '@angular/core';
import { AlertController } from 'mm-ui';
import { ClientCrudService } from './client-crud.service';
import { ListUsersService } from './list-users/list-users.service';
import { ReasonInterface } from '../client-editmotive/reason-interface';
import { ListReasonsComponent } from './list-reasons/list-reasons.component';
import { ListReasonsService } from './list-reasons/list-reasons.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'mm-client-crud',
  templateUrl: './client-crud.component.html',
  styleUrls: ['./client-crud.component.scss'],
})
export class ClientCrudComponent {

  users: boolean = true
  created: boolean = false
  createdReason: boolean = false;
  altered: string = ''
  name: string
  error : string = ''
  listUsers: Array<string>
  errorReason : string = 'false'
  reason: ReasonInterface
  status: string = ''
  messageStatus: string = '';
  messageText: string = '';
  messageStatusUser: string = '';
  messageTextUser: string = '';
  alter = {
    'enabled': 'habilitado',
    'disabled': 'desabilitado'
  }

  @Input() selectedIndex;

  @ViewChild('reasons', {static: true}) reasons: ListReasonsComponent;

  constructor(
    private alertController: AlertController,
    private clientCrudService: ClientCrudService,
    private listUserService: ListUsersService,
    private listReasonService: ListReasonsService,
    private utils : Utils
  ) {

      if(this.utils.hasStorage("tabActive")){
        this.selectedIndex = this.utils.getStorage("tabActive");
      }else{
        this.selectedIndex = "0";
      };
    }


    tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {

      this.utils.setStorage("tabActive", tabChangeEvent.index);
    }

  createUser(val){
    if(val.invalid){
      // this.error = 'email'
      this.messageStatusUser = 'danger'
      this.messageTextUser = 'E-mail inválido'

    } else if (!val.select){
      // this.error = 'type'
      this.messageStatusUser = 'danger'
      this.messageTextUser = 'Perfil de acesso inválido'

    } else {
      this.listUserService.getUsers().subscribe(resp => {
        this.listUsers = resp.content.map(e => {
            return e.email
        })
        if(this.listUsers.indexOf(val.input[0].value)!= -1){
          // this.error = 'already'
          this.messageStatusUser = 'danger'
          this.messageTextUser = 'Usuário já cadastrado.'

        } else {

          let user = {
            email: val.input[0].value
          }

          this.clientCrudService.createUser(user).subscribe(resp => {
            // this.error=''
            // this.created = true;
            this.messageStatusUser = 'success'
            this.messageTextUser = 'Usuário cadastrado com sucesso!'

          }, err => {
            if (err.error.errors[0] == 'user: aleready taken'){
              // this.error = 'already';
              this.messageStatusUser = 'danger'
              this.messageTextUser = 'Usuário já cadastrado'
            }
          })

        }
      })
    }
  }

  createReason(val) {

      let reasons: Array<string>

      this.listReasonService.getReasons(true).subscribe(resp => {
        reasons = resp.content.map(e => {return e.description})
        this.listReasonService.getReasons(false).subscribe(r => {
          r.content.forEach(element => {
            reasons.push(element.description)
          });

          if(reasons.indexOf(val[0].value) == -1){
            let reason = {
              description: val[0].value,
              active: true
            }
            this.clientCrudService.createReason(reason).subscribe(resp => {
              this.messageStatus = 'success'
              this.messageText = 'Motivo cadastrado com sucesso. Agora você poderá visualizar este item na lista dos motivos.'
              this.reason = resp;
              this.reasons.getReasons();
            }, err => {

              if(err.error.status === "BAD_REQUEST"){
                // this.status = 'already'
                this.messageStatus = 'danger'
                this.messageText = 'Motivo já cadastrado'
              } else {
                // this.status = ''
                // this.errorReason = 'error';
                this.messageStatus = 'danger'
                this.messageText = 'Não foi possível cadastrar o motivo.'
              }
            })
          } else {
            this.messageStatus = 'danger'
            this.messageText = 'Motivo já cadastrado'
          }
        })
      })
  }

  alertReason(){
    let config = {
      message: 'Cadastre um novo motivo para atribuir ao cliente.',
      title: 'Cadastro de novo motivo',

      input: [
        {
          label: 'Motivo de alteração cadastral',
          value: ''
        }
      ],
      buttons: [
        {
          text: 'Cadastrar motivo',
          type:'primary',
          handler: (val) => {
            this.createReason(val);
            this.alertController.close();
          }
        },
        {
          text: 'Cancelar',
          handler:() => {
            this.alertController.close();
          },
          type: 'outline-dark'
        }
      ]
    }
    this.alertController.configure(config)
    this.alertController.open()
  }

  getStatus(status){

    if(status === 'reasonEditedtrue'){
      this.messageStatus = 'success'
      this.messageText = 'O motivo foi ativado com sucesso.'
    } else if(status ==='reasonEditedfalse') {
      this.messageStatus = 'success'
      this.messageText = 'O motivo foi desativado com sucesso.'
    } else if (status === 'reasonEditedFail') {
      this.messageStatus = 'danger'
      this.messageText = 'Não foi possível alterar o motivo.'
    } else if (status === 'already') {
      this.messageStatus = 'danger'
      this.messageText = 'Motivo já cadastrado'
    } else if (status === 'altered') {
      this.messageStatus = 'success'
      this.messageText = 'Motivo alterado com sucesso!.'
    } else if (status === 'reasonEditedFail') {
      this.messageStatus = 'danger'
      this.messageText = 'Não foi possível alterar o motivo.'
    }
  }

  alertUser(){
    let config = {
      showSelectTag: true,
      message: 'Cadastre um novo usuário para utilizar a ferramenta e atribua um tipo de acesso.',
      title: 'Cadastro de novo usuário',
      input: [
        {
          label: 'E-mail do usuário',
          value: '',
          type: 'email'
        }
      ],
      selectOptionsLabel: 'Perfil de acesso',
      selectOptions: [
        {
          description: 'Selecionar',
          id:'none'
        },
        {
          description: 'Gestão',
          value:'MANAGER'
        },
        {
          description: 'Leitura',
          value:'VIEW'
        }
      ],
      buttons: [
        {
          text: 'Cadastrar usuário',
          type:'primary',
          handler: (val) => {
            this.createUser(val);
            this.alertController.close();
          }
        },
        {
          text: 'Cancelar',
          handler: () => {
            this.alertController.close();
          },
          type: 'outline-dark'
        }
      ]
    }

    this.alertController.configure(config)
    this.alertController.open()
  }

  userEnableDisable(value) {

    if(value.status == "altered"){
      this.messageStatusUser = 'success'
      this.messageTextUser = `Usuário ${value.name} alterado com sucesso!`
    } else if(value.status == "enabled"){
      this.messageStatusUser = 'success'
      this.messageTextUser = `Usuário ${value.name} habilitado com sucesso!`
    } else if(value.status == "disabled"){
      this.messageStatusUser = 'success'
      this.messageTextUser = `Usuário ${value.name} desabilitado com sucesso!`
    } else if (value.status == 'error'){
      this.messageStatusUser = 'danger'
      this.messageTextUser = value.name
    }
  }


  setUsers(event){
    if(event){
      this.users = true
    } else {
      this.users = false
    }
  }

}
