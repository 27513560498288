import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Globals{
    
    public _globals = {
        pagination: {
            page: 0,
            size: 20,
            sort: 'companyName,asc',
            query: '',
            last: false
        },
        
        navbar_items: [
    
            {
                logo_url:'/dashboard',
                settings_url:'/clients/crud',
                view_user_url:'/clients/view/'
            }
        
        ]
    };

    public get globals(): any {
        return this._globals;
    }
    
    public set globals(value: any) {
        this._globals = value;
    }
}
