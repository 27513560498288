import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store'
import { Client } from "../models/client"
import * as ClientFormActions from "./../actions/client-form.actions"

const initialState: Client = {
    cnpj: '',
    companyName: '',
    contact_email: '',
    contact_emails: '',
    contact_name: '',
    reportEmails:[],
    email: '',
    id: null,
    licenseType: null,
    modules: [],
    name: '',
    initialDate: '',
    finalDate: '',
    autoPartialBlock: true,
    observations: '',
    phone: '',
    qtMax: 0,
    qtMin: 0,
    send_email_type: null,
    url: null
}

export function clientSync(reducer: ActionReducer<Client>){
    return (state, action) => {
        let reducedState = reducer(state, action);
        if(action.type === INIT){
            const data = localStorage.getItem('client');
            if (data){
                let parsed : Client = <Client>JSON.parse(data)
                reducedState = Object.assign({},reducedState, parsed)
            };
        } else if (action.type !== UPDATE) {
            localStorage.setItem(
                'client',
                JSON.stringify(reducedState)
            );
        }
        return reducedState;
    };
}

export function reducerForm(state: Client = initialState, action: ClientFormActions.Actions) {
    switch(action.type){
        case ClientFormActions.SET_FORM:
            return Object.assign({},state, action.payload);
        case ClientFormActions.RESET_FORM:
            return initialState;
        default: 
            return state;
    }
}