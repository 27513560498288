import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "projects/f-monit/src/app/models/user";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { customHttp } from "../../../../utils/custom-http";
import { AppConfig } from "../../../../app.config";

@Injectable()

export class ListModuleService {
    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}`

    
    constructor(private http: HttpClient,
                private user: User){

    }

    getModules(avaliable: boolean): Observable<any> {
    let url = `${this.endpoint}/${this.apiVersion}/modules?available=${avaliable.toString()}`;
    let token = this.user.getToken();

    return this.http.get(url)
    }


}