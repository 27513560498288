
  <div class="container">

    <mmp5-breadcrumb></mmp5-breadcrumb>

    <section class="mm-box-container">

      <div class="row">
        <div class="col">
          <div *ngIf="altered" class="alert-adjust">
            <mm-alert [type]="alertClass" [text]="alertText"></mm-alert>
          </div>
        </div>
      </div>

      <div class="mm-box-content">
        <div class="search-head">
          <div class="search-input">
            <form [formGroup]="searchForm">
              <mm-autosearch (onTyping)="filter($event)"></mm-autosearch>
            </form>
          </div>

          <div class="justify-content-start btn-style">
            <a (click)="reindexAllClients()" class="btn btn-secondary mr-2">Reindexar usuários dos Clientes</a>
            <a (click)="reindexAllClientsMedias()" class="btn btn-secondary mr-2">Reindexar mídias dos Clientes</a>
            <a routerLink="/clients/form" class="btn btn-primary">Adicionar Cliente</a>
          </div>
        </div>

        <div class="x">
          <div class="justify-content-around">
            <mm-table
              [values]="tableData"
              [tableSize]="'800px'"
              [columnNames]="columnNames">
            </mm-table>

            <button class="btn btn-primary btn-style" (click)="load()" *ngIf="!last">Carregar mais Clientes</button>
          </div>
        </div>
      </div>
    </section>
  </div>
