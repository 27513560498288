<section class="mm-box-container">
        <header class="mm-box-header">
            <h6 class="m-0">Clientes ativos</h6>
        </header>            
        <div class="mm-box-content text-center">
            <div>
                <h1 class="card-text">{{activeClients?.totalElements}}</h1>
                <div class="m-1">
                    <button class="btn-as-link" (click)="verlistaClientes()">Ver lista clientes</button><br>
                </div>
                <button (click)="cadastrarCli()" class="btn btn-primary btn-adjust">Cadastrar cliente</button>
            </div>
        </div>
</section> 