import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';



@Component({
  selector: 'mmp5-page-internalcustomer_unlockClient',
  templateUrl: './page-internalcustomer_unlockClient.html',
  styleUrls: ['./page-internalcustomer_unlockClient.scss']
})
export class PageInternalCustomer_UnlockClientComponent implements OnInit {

  input = new UntypedFormControl();


  constructor(
    private vcr: ViewContainerRef) {
    
  }

  ngOnInit() {
  }

}
