import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_BASE_URL, RecaptchaLoaderService } from 'ng-recaptcha';

import { routing } from './app.routes';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core.module';
import { ViewModule } from './layouts/view/view.module';
import { OpenComponent } from './layouts/open/open.component';

import { environment } from '../environments/environment';

import { LoadingSpinnerModule, MMIconsModule } from 'mm-ui';

import { ToastModule } from 'primeng/toast';

registerLocaleData(localePt);

import { ActiveClientsBoxModule } from './reusable/active-clients-box/active-clients-box.module';
import { InterceptorModule } from './modules/interceptor.module';

import { MetaReducer, StoreModule } from '@ngrx/store';
import { reducerForm, clientSync } from "./reducers/client-form.reducer";
import { reducerEdit } from "./reducers/client-edit-reducer";
import { AppToastModule } from './modules/toastModule.module';
import { BehaviorSubject, Observable } from 'rxjs';

@NgModule({
  declarations: [
    AppComponent,
    OpenComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule,
    CoreModule,
    BrowserAnimationsModule,
    ViewModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,
    RecaptchaV3Module,
    routing,
    InterceptorModule,
    ActiveClientsBoxModule,
    LoadingSpinnerModule,
    MMIconsModule,
    ToastModule,
    AppToastModule.forRoot(),
    StoreModule.forRoot({
      clientForm: reducerForm,
      clientEdit: reducerEdit
    },
      {
        metaReducers: [
          clientSync
        ] as unknown as MetaReducer<any>[]
      })
  ],
  providers: [
    //{ provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.key },
    // { provide: RECAPTCHA_BASE_URL, useValue: environment.recaptcha.url },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor() {
    console.log({ data: environment.recaptcha.key })
  }
}
