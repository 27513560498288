import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { User } from "../models/user";

@Injectable()

export class ClientCreatedGuard {

  constructor(private router: Router,
    private user: User) {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let clientid = localStorage.getItem('clientId')
    if (this.user.isAuthenticated() && clientid != null) {
      localStorage.removeItem('clientId')
      return true
    } else {
      this.router.navigate(['/clients/form']);
      return false
    }
  }
}
