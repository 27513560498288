<div class="yellow">
    <div class="margins">
        <section class="mm-box-container car-login">
            <div class="mm-box-content">
                <div class="row justify-content-center mb-2">
                    <div class="col-auto">
                        <img class="logo" class="img-responsive" src="../../assets/mmp5.png">
                    </div>
                </div>
                <div class="row justify-content-center mb-2">
                    <div class="col-12 text-center">
                        <p class="mb-0">Bem vindo ao MMP5!</p>
                        <p>Faça seu login para acessar o sistema</p>
                    </div>
                </div>
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <mmp5-fieldset-haruki [formControl]="email" [key]="'email'" [label]="'E-mail'" [showError]="false">
                            </mmp5-fieldset-haruki>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <mmp5-fieldset-haruki [formControl]="password" [key]="'password'" [showError]="false"
                                [label]="'Senha'" [type]="'password'" [tooltip]="'Mínimo de 8 caracteres entre letras e números'">
                            </mmp5-fieldset-haruki>


                            <a routerLink="/email-reset" class="mt-1 mb-1 btn-as-link" (click)="passReset()">Esqueceu
                                sua senha?</a>
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                      <button type="submit" (click)="login('login')" [disabled]="form.invalid" class="btn btn-secondary">Enviar</button>
                    </div>
                        <div class="alert alert-danger disabled mt-2" id="wrong">
                            Não foi possivel realizar o login, alguns dados informados estão incorretos.
                            Verifique e tente novamente ou redefina sua senha.
                        </div>
                        <p class="mt-1" *ngIf="gitHash">Hash da versão: {{gitHash}}</p>
                        <p *ngIf="gitCount">Versão do projeto: {{gitCount}}</p>
                        <!-- <p *ngIf="gitDate">Data da versão: {{gitDate}}</p> -->
                </form>
            </div>
        </section>
    </div>
</div>
