<ng-progress
  [speed]="400"
  [spinner]="true"
  [color]="'#ff5e3a'">
</ng-progress>
<p-toast></p-toast>
<section>
  <router-outlet></router-outlet>
</section>
<script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
