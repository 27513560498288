import { Component, OnInit, Output, EventEmitter, ViewContainerRef, Input } from "@angular/core";
import { AlertController } from "mm-ui";

import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { UserInterface } from "../../../../../../../mm-ui/src/lib/navbar/user-interface";
import { ListUsersService } from "./list-users.service";

@Component({
    selector: "mm-list-users",
    templateUrl: "list-users.component.html",
    styleUrls: ['list-users.component.scss'],
})

export class ListUsersComponent implements OnInit{

    @Output() userChangeEnable = new EventEmitter;
    @Output() hasUsers = new EventEmitter

    users: Array<UserInterface>
    form: UntypedFormArray
    selectedUser: UserInterface
    usersData: Array<any> = []
    columnNames: Array<string> = [
      'Email do usuário',
      'Perfil de acesso',
      'Ativo?',
      'Editar'
    ]

    role = {
        'ROOT': "Administrador",
        'MANAGER': "Gestão",
        'VIEW': "Leitura"
    }

    @Input('scroll-size') scrollSize:string;

    constructor(private listUserService: ListUsersService,
                private formBuilder: UntypedFormBuilder,
                private alertController: AlertController){

         this.form = this.formBuilder.array([])
    }

    ngOnInit(){
        this.listUserService.getUsers().subscribe(resp => {
            this.users = resp.content
            this.users.forEach(e => {
                this.form.push(
                    this.formBuilder.group({
                      enable: new UntypedFormControl(e.enabled),
                      id: new UntypedFormControl(e.id),
                      email: new UntypedFormControl(e.email),
                      name: new UntypedFormControl(e.name),
                    }))
            })
            if(this.users){
                this.getTableData()
                this.getEnableChange();
                this.hasUsers.emit('true');
            } else {
                this.hasUsers.emit('false')
            }
        })
    }

    enableDisableUser(event, i){
        let id = (<UntypedFormGroup>this.form.controls[i]).controls.id.value
        this.listUserService.enableDisable(id).subscribe(resp => {
            if(resp.enabled){
                this.userChangeEnable.emit({
                    status: "enabled",
                    name: resp.email
                })
            } else {
                this.userChangeEnable.emit({
                    status:"disabled",
                    name: resp.email
                })
            }
        }, err => {
            this.userChangeEnable.emit({
                status:"error",
                name: "Não foi possível alterar status do usuário."
            })
        })

    }

    getTableData() {
        this.users.forEach((user, i) => {

            let tableRow = {
                [ListUsersNames.email] : user.email,
                [ListUsersNames.role] : this.role[user.role],
                [ListUsersNames.enable]: [{
                    formControl: (<UntypedFormGroup>this.form.controls[i]).controls.enable,
                    type: 'switch',
                    key: 'ListUserSwitch'+i
                }],
                beforeIcons: [null,null,null,['icon','icon-toolbar-editar']],
                iconEmitter: true
            }

            this.usersData = [
                ...this.usersData,
                tableRow
            ]
        })
    }

    getEnableChange() {
        this.form.controls.forEach((control : UntypedFormGroup, i) => {
            control.controls.enable.valueChanges.subscribe(resp => {

                this.enableDisableUser(resp, i)
            })
        })
    }

    editTable(e){
        let index = this.usersData.indexOf(e)
        this.editUser(index)
    }

    sendEdit(inputs){
        this.selectedUser.email = inputs.input[0].value
        if(inputs.select){
            this.selectedUser.roles = inputs.select
        }

        this.listUserService.editUser(this.selectedUser.id, this.selectedUser).subscribe(resp => {
            this.userChangeEnable.emit({
                status: "altered",
                name: resp.email
            })
        }, err => {
            this.userChangeEnable.emit({
                status: "createdFail",
                name: 'Não foi possível editar usuário'
            })
        })
    }

    editUser(i){
        let email = (<UntypedFormGroup>this.form.controls[i]).controls.email.value
        this.selectedUser = this.users.find( u => u.email == email);
        let config = {
            showSelectTag: true,
            title: 'Editar usuário cadastrado',
            message: 'Edite o usuário ou o perfil de acesso dele na ferramenta.',
            input: [
                {
                    label: 'E-mail do usuário',
                    value: email
                }
            ],
            selectOptionsLabel: 'Perfil de acesso',
            selectOptions: [
                {
                    description: 'Selecionar',
                    id:'none'
                },
                {
                    id: 'VIEW',
                    description: 'Leitura'
                },
                {
                    id: 'MANAGER',
                    description: 'Gestão'
                }
            ],
            buttons: [
                {
                    text: 'Salvar edição',
                    handler: (val) => {
                        this.sendEdit(val)
                        this.alertController.close()
                    },
                     type: 'primary'
                },
                {
                    text: 'Cancelar',
                    handler: () => {
                        this.alertController.close()
                    },
                    type: 'outline-dark'
                }
            ]
        }

        this.alertController.configure(config)

        this.alertController.open();
    }

}

export enum ListUsersNames {
    email = 'Email do usuário',
    role = 'Perfil de acesso',
    enable = 'Ativo?',
    edit = 'Editar'
}


