import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AppConfig } from "../../app.config";
import { ReCaptchaV3Service } from "ng-recaptcha";


@Injectable ()

export class LoginService {
    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}`
    git = AppConfig.data;

    constructor (
        private http: HttpClient,
        private recaptchaV3Service: ReCaptchaV3Service,
        ) {}

    signin(data: {email: string, password: string, recaptchaToken: string}): Observable<any>{
      let credentials = {};
      if(data.recaptchaToken){

        credentials = {
            grant_type: 'password',
            email: data.email,
            password: data.password,
            "g-recaptcha-response": data.recaptchaToken
        }
      }else{
        credentials = {
          grant_type: 'password',
          email: data.email,
          password: data.password
        }
      }

        return this.http.post<any>(`${this.endpoint}/${this.apiVersion}/oauth/token`, credentials)
    }

    passReset(data: {email: string, 'g-email-response'?: string}): Observable<any> {
        return this.http.post<any>(`${this.endpoint}/${this.apiVersion}/users/email_reset`, data)
    }

    googleRecapcha(){
        return this.http.get<{googleRepachaSiteKey: string}>(`${this.endpoint}/${this.apiVersion}/recaptchasitekey`)
    }

    googleRecaptchaV3(act){
        return this.recaptchaV3Service.execute(act)
    }

    changePass(data: {resetPasswordToken: string, password: string, passwordConfirmation: string}){
        return this.http.post<any>(`${this.endpoint}/${this.apiVersion}/users/reset_password`, data)
    }

    getGitHash(){
        return this.git.hash ? this.git.hash : null
    }

    getGitCount(){
        return this.git.count
    }

    getGitDate(){
        return this.git.date
    }
}
