import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import { LoginService } from "../login.service";
import { AppConfig } from "../../../app.config";
import { Subscription } from "rxjs";


@Component({
    selector: 'mm-email-login',
    templateUrl: 'email-login.component.html',
    styleUrls: ['email-login.component.scss', '../login.component.scss']
})

export class EmailLoginComponent implements OnInit, OnDestroy{
    
    password = new UntypedFormControl('', [Validators.required]);
    confirmPassword = new UntypedFormControl ('', [Validators.required, RxwebValidators.compare({fieldName:'password'})])
    token: any;
    formEmailPass: UntypedFormGroup
    error: boolean = false
    activation: boolean = false;
    sended: boolean;
    hasRecaptcha = !AppConfig.data['recaptcha']['disabled'];
    key: string;
    errorActivation: boolean = false;
    recentToken:string = '';
    private emailLoginExecutionSubscription: Subscription;
    
    
    constructor(private formbuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private loginservice: LoginService){
            
        this.formEmailPass = formbuilder.group({
                password: this.password,
                confirmPassword: this.confirmPassword,                
        })
    }

    ngOnInit(){
        this.token= this.route.snapshot.params.token;
        this.activation = this.route.snapshot.params.activated
        this.errorActivation = this.route.snapshot.params.error
        this.validateToken(this.token);
   
    }

    validateToken(token: string){
        if(token.indexOf('-')!=-1){
            return true
        } else {
            this.router.navigate(['/login']);
        }
    }

    onChange(){
        if (this.password.value == this.confirmPassword.value){
            let a = <HTMLElement>document.getElementById('alert');
            a.classList.add('disabled');
        }else {
            let a = <HTMLElement>document.getElementById('alert');
            a.classList.remove('disabled');
        }
    }

    login(act?) {

        this.recentToken = '';

        if(this.hasRecaptcha){

            if (this.emailLoginExecutionSubscription) {
                console.log('sigle')
                this.emailLoginExecutionSubscription.unsubscribe();
            }

            this.emailLoginExecutionSubscription = this.loginservice.googleRecaptchaV3(act)
                .subscribe((token) => { 

                    this.recentToken = token;
                    this.changePass();
                }
            );

        }else{

            console.log('recaptcha disabled');
            this.changePass();
        }
    }


    changePass() {
        this.loginservice.changePass({resetPasswordToken: this.token, password: this.password.value, passwordConfirmation: this.confirmPassword.value}).subscribe(resp => {
                this.loginservice.signin({
                    email: resp.email,
                    password: this.password.value,
                    recaptchaToken: this.recentToken                
                }).subscribe( response=> {
                    localStorage.setItem('accessToken', response.accessToken)
                    this.router.navigate(['dashboard']);            
                }, err => {
                    this.error = true
                })
        }, err => {
            this.error = true            
        })
    }

    ngOnDestroy(): void {
        if(this.emailLoginExecutionSubscription)
            this.emailLoginExecutionSubscription.unsubscribe()
    }

}