import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../../app.config";
import { Injectable } from "@angular/core";

@Injectable()

export class ListUsersService {

    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`

    constructor(private http: HttpClient){

    }

    editUser(id: string, data: any){
        return this.http.put<any>(`${this.endpoint}/users/${id}`, data)
    }

    enableDisable(id: string){
        return this.http.get<any>(`${this.endpoint}/users/${id}/toggle`)
    }
    getUsers() {
        return this.http.get<any>(`${this.endpoint}/users?size=100&sort=enabled,desc`)
    }
}