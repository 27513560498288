import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';



@Component({
  selector: 'mmp5-page-internalcustomer_blockClient',
  templateUrl: './page-internalcustomer_blockClient.html',
  styleUrls: ['./page-internalcustomer_blockClient.scss']
})
export class PageInternalCustomer_BlockClientComponent implements OnInit {

  input = new UntypedFormControl();
  
  ngOnInit() {
  }


}
