import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, Input } from "@angular/core";
import { ReportsList } from "../../../../interfaces/reportslists";
import { ListReportsService } from "./list-reports.service";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from "@angular/forms";
import { AlertController } from "mm-ui";
import { Utils } from "../../../../utils/utils";


@Component({
    selector: 'mm-list-reports',
    styleUrls: ['./list-reports.component.scss','../list-modules/list-modules.component.scss'],
    templateUrl: './list-reports.component.html',
    encapsulation: ViewEncapsulation.None,
    providers:[Utils]
})

export class ListRepoertsComponent implements OnInit{

  reports: Array<ReportsList> = []
  form: UntypedFormArray
  selectedReport: ReportsList
  eReport: ReportsList
  reportsList: Array<any> = []
  columnName: Array<string> = [
      'Relatório',
      'Ativo?',
      'Editar'
  ]

  @Input('scroll-size') scrollSize:string;

  @Output('status') status = new EventEmitter;
  oldForm: any;


  constructor(
    private listReportService: ListReportsService,
    private alertController: AlertController,
    private formBuilder: UntypedFormBuilder,
    private utils: Utils
  ){}

  ngOnInit(){
    this.reportList();
  }

  reportList(){
    this.form = this.formBuilder.array([])
    this.listReportService
      .getReports()
      .subscribe( val => {

        this.reports = [];

        val.forEach(rep => {
          this.reports.push(rep)

          this.form.push(
              this.formBuilder.group({
                  id: this.formBuilder.control(rep.id),
                  title: this.formBuilder.control(rep.title),
                  status: this.formBuilder.control(rep.status == 'ACTIVE' ? true : false),
                  key: this.formBuilder.control(rep.key)
              })
          )
      });
      this.oldForm = this.form.value
      this.getTableData();
      this.formChange();
      })

  }

  tableEditReports(e){
    let report = this.form.value.filter(item => item.key == (e[ReportName.enable][0].key))
    this.alertReport(report)
  }

  getTableData(){
    this.reportsList = []
    this.reports.map((report, i) => {
        let tableRow = {
            [ReportName.title] : report.title,
            [ReportName.enable]: [{
                formControl: (<UntypedFormGroup>this.form.controls[i]).controls.status,
                type: 'switch',
                key: report.key
            }],
            beforeIcons: [null,null,['icon icon-toolbar-editar']],
            iconEmitter: true
        }
        this.reportsList = [
            ...this.reportsList,
            tableRow
        ]
    })
  }

  formChange(){
    this.form.controls.forEach((control, i) => {
      control.valueChanges.subscribe(resp => {

          let status = resp.status ? 'ACTIVE': 'INACTIVE';
          this.listReportService.updateStatus(resp.id, {status})
          .subscribe(
            {
              next: () => { console.log('update_status_success'); },
              error: (err) => { this.utils.toastMessage('error','Status', 'Erro ao alterar o status do relatório'); console.error('something wrong occurred: ' + err); },
              complete: () =>{ this.utils.toastMessage('success','Status', 'Status do relatório alterado.') }
            }
          )
      })
  })
  }

  alertReport(report?){
    let config = null

    if(report){
      config = {
        message: 'Edite este relatório Jasper.',
        title: 'Editando o relatório',

        input: [
          {
            label: 'Nome',
            value: report[0].title
          },
          {
            label: 'Arquivo Jasper',
            type: 'file',
            value: ''
          }
        ],
        buttons: [
          {
            text: 'Cadastrar relatório',
            type:'primary',
            handler: (val) => {
              this.uploadReport(val, report[0]);
              this.alertController.close();
            }
          },
          {
            text: 'Cancelar',
            handler:() => {
              this.alertController.close();
            },
            type: 'outline-dark'
          }
        ]
      }
    }else{
      config = {
        message: 'Cadastre um novo relatório Jasper.',
        title: 'Cadastro de novo relatório',

        input: [
          {
            label: 'Nome',
            value: ''
          },
          {
            label: 'Arquivo Jasper',
            type: 'file',
            value: ''
          }
        ],
        buttons: [
          {
            text: 'Cadastrar relatório',
            type:'primary',
            handler: (val) => {
              this.uploadReport(val);
              this.alertController.close();
            }
          },
          {
            text: 'Cancelar',
            handler:() => {
              this.alertController.close();
            },
            type: 'outline-dark'
          }
        ]
      }
    }

    this.alertController.configure(config)
    this.alertController.open()
  }

  uploadReport(val, report?){
    if(report){

      this.listReportService
      .updateReport(report.id, {"title":val[0].value, "base64": val[1].value})
      .subscribe({
        next: () => {console.log('upload_success');},
        error: (err) => { this.utils.toastMessage('error','Relatório', 'Erro ao editar o relatório.'); console.error('something wrong occurred: ' + err); },
        complete: () => { this.utils.toastMessage('success','Relatório', 'Relatório editado.'); this.reportList(); console.log('done'); }
      })

    }else{

      this.listReportService
        .createReport({"title":val[0].value, "base64": val[1].value})
        .subscribe({
          next: () => { console.log('create_success'); },
          error: (err) => { this.utils.toastMessage('error','Relatório', 'Erro ao criar o relatório.'); console.error('something wrong occurred: ' + err); },
          complete: () => { this.utils.toastMessage('success','Relatório', 'Relatório criado com sucesso.'); this.reportList(); console.log('done'); }
      })
    };

  }

}

export enum ReportName {
  title = 'Relatório',
  enable = 'Ativo?',
  edit = 'Editar'
}

