<header>
  <div>
      {{ 'header.saudation' | translate:{ email: email } }}
      <button
        class="btn-as-link"
        (click)="signOut()">
        {{ 'header.signout' | translate }}
      </button>
  </div>
</header>

<router-outlet></router-outlet>
