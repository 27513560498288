import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy } from "@angular/core";
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { LoginService } from "./login.service";
import { Router } from "@angular/router";
import { AppConfig } from "../../app.config";
import * as moment from "moment";
import { Subscription } from "rxjs";

@Component({
    selector:'mmp5-login',
    templateUrl:'login.component.html',
    styleUrls:['login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    email = new UntypedFormControl('', [Validators.required, Validators.email]);
    password = new UntypedFormControl('', [Validators.required]);
    hasRecaptcha = !AppConfig.data['recaptcha']['disabled'];
    key: string;
    gitHash: string;
    recentToken:string = '';
    private singleExecutionSubscription: Subscription;

    form = new UntypedFormGroup ({
        email : this.email,
        password: this.password
    })
    gitCount: any;
    gitDate: any;

    constructor(private loginservice: LoginService,
        private formbuilder: UntypedFormBuilder,
        private router: Router){

        }

    ngOnInit(): void {

        this.getVersion();

    }

    getVersion(){
        this.gitHash = this.loginservice.getGitHash()
        this.gitCount = this.loginservice.getGitCount();
        this.gitDate = moment(this.loginservice.getGitDate()).format('DD/MM/YYYY - HH:mm');
    }

    login(act?) {

        this.recentToken = '';

        if(this.hasRecaptcha){

            if (this.singleExecutionSubscription) {
                console.log('sigle')
                this.singleExecutionSubscription.unsubscribe();
            }

            this.singleExecutionSubscription =this.loginservice.googleRecaptchaV3(act)
                .subscribe((token) => {

                    this.recentToken = token;
                    this.loginAct()
                }
            );

        }else{

            console.log('recaptcha disabled')
            this.loginAct()
        }
    }

    loginAct(){
        this.loginservice.signin({
            email: this.email.value,
            password: this.password.value,
            recaptchaToken: this.recentToken
        }).subscribe({
          next: (response:any) => {
            localStorage.setItem('accessToken', response.accessToken)
            let redirect = localStorage.getItem('redirectTo')
            if(redirect){
                localStorage.removeItem('redirectTo')
                this.router.navigate([redirect])
            }else {
                this.router.navigate(['dashboard']);
            }
        },
        error: err => {
          console.log(err)
          let msg = <HTMLElement>document.getElementById('wrong');
          msg.classList.remove('disabled');
        }
      })
    }

    passReset() {
        this.router.navigate(['email-reset']);
    }

    ngOnDestroy(): void {
        if(this.singleExecutionSubscription)
            this.singleExecutionSubscription.unsubscribe()
    }

}
