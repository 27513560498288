import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ClientActiveService } from "./client-active.service";

@Component({
    selector: 'mm-client-active',
    templateUrl: 'client-active.component.html',
    styleUrls: ['client-active.component.scss', '../../login/login.component.scss']
})

export class ClientActiveComponent implements OnInit {

    licenseKey = new UntypedFormControl ('', [Validators.required]);
    token: string;
    activation: boolean;
    errorActivation: boolean;
    disable: boolean;
    sended: boolean;

    constructor(
        private route: ActivatedRoute,
        private clientActiveService: ClientActiveService
    ){

    }

    ngOnInit(){
        this.token = this.route.snapshot.params.token;
    }

    activeClient(){
        let data = {
            clientActivationToken: this.token,
            licenseKey: this.licenseKey.value
        }
        this.disable = true
        this.clientActiveService.sendActive(data).subscribe(resp => {
            this.activation = true;
            this.errorActivation = false
        }, err => {
            this.disable = false
            this.errorActivation = true;
            this.activation = false;
        })        
    }
    
}